import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AlertTitle,
  Button,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Divider,
  CircularProgress,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../assets/styles/datagrid-improved.css"
import { Box } from "@mui/system";


function DataGridImprovedCobList({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading,handleClickOpenUpdateDueDate,HandleOpenUpdateMarkedPaid,blockAction,ChangeStatus
}) {


  return (
    <React.Fragment>

          
{window.isNativeApp && 
loading && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}

    {!window.isNativeApp &&
        <DesktopTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        
      />
      }

    {window.isNativeApp &&
        <MobileTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        handleClickOpenUpdateDueDate={handleClickOpenUpdateDueDate}
        HandleOpenUpdateMarkedPaid={HandleOpenUpdateMarkedPaid}
        blockAction={blockAction}
        ChangeStatus={ChangeStatus}
      />
      }

    </React.Fragment>

  );
}


function DesktopTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading
}) {
  return (
    <React.Fragment>
      <DataGrid
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            page={page}
            autoHeight={true}
            onPageSizeChange={onPageSizeChange }
            paginationMode={'server'}
            onPageChange={onPageChange }
            loading={loading}
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}

            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum Boleto gerado encontrado.
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
          />
      

    </React.Fragment>

  );
}

function MobileTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading,handleClickOpenUpdateDueDate,HandleOpenUpdateMarkedPaid,blockAction,ChangeStatus
}) {
  const navigate = useNavigate();
  const ColorButtonTransaction =(status)=>{
    let color="primary";
    if(status==0){
      color="boot_warning"
    }else if(status==1){
      color="boot_success"
    }else if(status==2){
      color="boot_info"
    }else if(status==3){
      color="boot_secondary"
    }else if(status==4){
      color="boot_dark"
    }else if(status==5){
      color="boot_danger"
    }else if(status==6){
      color="boot_dark"
    }else if(status==7){
      color="boot_success"
    }else if(status==8){
      color="boot_success"
    }else if(status==9){
      color="boot_success"
    }else if(status==10){
      color="boot_secondary"
    }
  
    return color;
  }
 

   //  console.log( Object.getOwnPropertyNames(row));
     // console.log(row);
   
  
  return (
    <React.Fragment>

    <section id="no-more-tables">
    
<table>
	<thead>
		<tr>
      {columns.map(el => 
      	<th>{el.headerName}</th>
      )
    }
			
		</tr>
	</thead>
	<tbody>
  {
    rows.map( elc =>
		<tr style={{
      marginTop:15
    
    }}>
 
      <td data-title={'ID' }>
      <Link href={"/cobrancas/show/"+elc.id} target="_blank" style={{color:'#0066cc '}}>
                          {elc.id}
      </Link></td>
      <td data-title={'Referência Pedido' }>{elc.reference?elc.reference:" - "}</td>
      <td data-title={'Emissão' }>{new Date(elc.created_at).toLocaleDateString('pt-br')}</td>
      <td data-title={'Vencimento' }>{new Date(elc.due_date).toLocaleDateString('pt-br')}</td>
      <td data-title={'Data de Pagamento' }>{elc.payment_date??" - "}</td>
      <td data-title={'Valor Total' }>{elc.amount??" - "}</td>
      <td data-title={"Valor Pago"}>
         <Typography>
          {
           ( elc.status["id"]!=7?elc.amount_paid:
           elc.amount_marked_paid)>0?
           parseFloat(( elc.status["id"]!=7?elc.amount_paid:
           elc.amount_marked_paid)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):" - "

          }
        </Typography></td>
      <td data-title={'Status' } style={{marginBottom:5}}>
      <Button
          variant="contained"
          size="small"
          sx={{fontSize:12}}
          color={elc.status?ColorButtonTransaction(elc.status["id"]):"primary"}
          onClick={e => navigate("/cobrancas/show/"+elc.id)}>
          {elc.status?elc.status["name"]:null}
        </Button></td>

      <td data-title={'Ação' } style={{marginBottom:5}} >
      <Button variant='outlined' color="boot_primary" size={"small"} 
        disabled={![0,3,10].includes(elc.status["id"]) || blockAction} onClick={e=> HandleOpenUpdateMarkedPaid(elc)}
        style={{marginBottom:6}}>
                    Marcar como pago
                </Button>
                
                <Button variant='outlined' color="secondary" onClick={e=> handleClickOpenUpdateDueDate(elc)} size={"small"} disabled={(!blockAction && elc.status["id"]!=0 ) || blockAction  } >
                    Atualizar
                </Button>
                <Button variant='outlined' color="boot_danger" size={"small"} disabled={![0,7,10].includes(elc.status["id"])
            || blockAction}   onClick={e=> ChangeStatus("cancelado", elc)}>
                    Cancelar
                </Button>
                {elc.link &&
                    <Link
                      target="_blank"
                      href={elc.link}>
                      <Button
                          variant="contained"
                          size="small"
                          color={"boot_warning"}>
                        Abrir
                      </Button>
                    </Link>
}
      </td>
        
		</tr>
    )
  }
	</tbody>
</table>
<Divider my={3} ></Divider>

  <br></br>
  <br></br>
  <div class="pagination">
    
    <a href="#" onClick={e=> onPageChange(page-1)}>&laquo;</a>
    <a href="#">Página {page+1}</a>
    <a href="#" onClick={e=> onPageChange(page+1)}>&raquo;</a>

  </div>
</section>

    </React.Fragment>

  );
}


export default DataGridImprovedCobList;
