import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AlertTitle,
  Button,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Divider,
  CircularProgress,
  Stack,
  Paper,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "../../../assets/styles/datagrid-improved.css"
import { Box } from "@mui/system";


function DataGridImprovedTransactionList({
  invoices,HandleOpenUpdateDueDate,ChangeStatus,navigate,blockAction,WhatsAppIcon,HandleOpenUpdateMarkedPaid

}) {


  return (
    <React.Fragment>



    {!window.isNativeApp &&
        <DesktopTable
        invoices={invoices}
    HandleOpenUpdateDueDate={HandleOpenUpdateDueDate}
    ChangeStatus={ChangeStatus}
    navigate={navigate}
    blockAction={blockAction}
    WhatsAppIcon={WhatsAppIcon}
    HandleOpenUpdateMarkedPaid={HandleOpenUpdateMarkedPaid}
        
      />
      }

    {window.isNativeApp &&
        <MobileTable
        invoices={invoices}
    HandleOpenUpdateDueDate={HandleOpenUpdateDueDate}
    ChangeStatus={ChangeStatus}
    navigate={navigate}
    blockAction={blockAction}
    WhatsAppIcon={WhatsAppIcon}
    HandleOpenUpdateMarkedPaid={HandleOpenUpdateMarkedPaid}
      />
      }

    </React.Fragment>

  );
}


function DesktopTable({
  invoices,HandleOpenUpdateDueDate,ChangeStatus,navigate,blockAction,WhatsAppIcon,HandleOpenUpdateMarkedPaid
}) {
  return (
    <React.Fragment>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">V. Unitário</TableCell>
            <TableCell align="left">V. Pago</TableCell>
            <TableCell align="left">Parcela</TableCell>
            <TableCell align="left">Vencimento</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow
              key={invoice.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                 <Link onClick={e => navigate("/cobrancas/show/"+invoice.id)}>
                    
                        {invoice.id} 
                </Link>
              </TableCell>
              <TableCell align="right">{parseFloat(invoice.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
              <TableCell align="right">{parseFloat(
                invoice.status["id"]!=7?invoice.amount_paid>0?invoice.amount_paid:0:
                invoice.amount_marked_paid>0?invoice.amount_marked_paid:0
              )>0&&parseFloat(
                invoice.status["id"]!=7?invoice.amount_paid:invoice.amount_marked_paid>0?invoice.amount_marked_paid:0
              ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
              <TableCell align="right">{invoice.position}</TableCell>
              <TableCell align="right">{new Date(invoice.due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
              <TableCell align="right">{invoice.status["name"]}</TableCell>
              <TableCell align="right">
                <Button variant='outlined' color="boot_primary" size={"small"} disabled={(!blockAction && ![0,3,10].includes(invoice.status["id"])) || blockAction} onClick={e=> HandleOpenUpdateMarkedPaid(invoice)}>
                    Marcar como pago
                </Button>
                
                <Button variant='outlined' color="secondary" onClick={e=> HandleOpenUpdateDueDate(invoice)} size={"small"} disabled={(blockAction ||  invoice.status["id"]!=0 ) || blockAction  } >
                    Atualizar
                </Button>
                <Button variant='outlined' color="boot_danger" size={"small"} disabled={(blockAction || ![0,7,10].includes(invoice.status["id"]))
            || blockAction}   onClick={e=> ChangeStatus("cancelado", invoice)}>
                    Cancelar
                </Button>
                {invoice.link &&
                    <Link
                      target="_blank"
                      href={invoice.link}>
                      <Button
                          variant="contained"
                          size="small"
                          color={"boot_secondary"}>
                        Abrir
                      </Button>
                    </Link>
                  
                  }
                  {invoice.link &&
                    <Link
                      target="_blank"
                      href={invoice.link+"/pdf"}>
                      <Button
                          variant="contained"
                          size="small"
                          color={"boot_warning"}>
                        PDF
                      </Button>
                    </Link>
                  
                  }
                 {invoice.whatsapp_link &&
                 
                 <Link
                  target="_blank"
                  href={invoice.whatsapp_link}>
                  <Button
                          variant="outlined"
                          size="small"
                          color={'boot_success'}>
                          <WhatsAppIcon />
                  </Button>
                  </Link>
                 
                 
                 } 


              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>

  );
}

function MobileTable({
  invoices,HandleOpenUpdateDueDate,ChangeStatus,navigate,blockAction,WhatsAppIcon,HandleOpenUpdateMarkedPaid

}) {
  
  return (
    <React.Fragment>

    <section id="no-more-tables">
    
<table>
	<thead>
		<tr>
    <th>ID</th>
    <th>V. Unitário</th>
    <th>V. Pago</th>
    <th>Parcela</th>
    <th>Vencimento</th>
    <th>Status</th>
    <th></th>
			
		</tr>
	</thead>
	<tbody>
  {
    invoices.map( elc =>
		<tr style={{
      marginTop:15
    
    }}>
 
   
      <td data-title={'ID' }>
      <Link onClick={e => navigate("/cobrancas/show/"+elc.id)}>
                    
                    {elc.id} 
            </Link>
      </td>
      <td data-title={'Valor Unitário' }>{parseFloat(elc.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
      <td data-title={'Valor Pago' }>{
        (
          parseFloat(
          elc.status["id"]!=7?elc.amount_paid>0?elc.amount_paid:0:
          elc.amount_marked_paid>0?elc.amount_marked_paid:0
          )>0&&parseFloat(
            elc.status["id"]!=7?elc.amount_paid:elc.amount_marked_paid>0?elc.amount_marked_paid:0
          ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          
        )?
        (
          parseFloat(
          elc.status["id"]!=7?elc.amount_paid>0?elc.amount_paid:0:
          elc.amount_marked_paid>0?elc.amount_marked_paid:0
          )>0&&parseFloat(
            elc.status["id"]!=7?elc.amount_paid:elc.amount_marked_paid>0?elc.amount_marked_paid:0
          ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          
        )
        :' - '
      }
              
      </td>

      <td data-title={'Parcela' }>{elc.position}º</td>

      <td data-title={'Vencimento' }>{new Date(elc.due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
      <td data-title={'Status' }>{elc.status["name"]}</td>
      <td data-title={'Referência' }>{elc.reference!=""?elc.reference:' - '}</td>   

      <td data-title={'Ação' } style={{marginTop:5}}>
      <React.Fragment>
          <Grid sx={{marginBottom:3}}>
            <Button variant='outlined' color="boot_primary" size={"small"} disabled={(!blockAction && ![0,3,10].includes(elc.status["id"])) || blockAction} onClick={e=> HandleOpenUpdateMarkedPaid(elc)}>
            Marcar como pago
            </Button>
                  
            
          </Grid>
          <Grid sx={{marginBottom:3}}>
          <Button variant='outlined' color="secondary" onClick={e=> HandleOpenUpdateDueDate(elc)} size={"small"} disabled={(blockAction ||  elc.status["id"]!=0 ) || blockAction  } >
                Atualizar
            </Button>
            <Button variant='outlined' color="boot_danger" size={"small"} disabled={(blockAction || ![0,7,10].includes(elc.status["id"]))
            || blockAction}   onClick={e=> ChangeStatus("cancelado", elc)}>
                    Cancelar
                </Button>
            </Grid>
          <Grid sx={{marginBottom:3}} >

          

                {elc.link &&
                    <Link
                      target="_blank"
                      href={elc.link}>
                      <Button
                          variant="contained"
                          size="small"
                          color={"boot_secondary"}>
                        Abrir fatura
                      </Button>
                    </Link>
                  
                  }
          </Grid>


             
          <Grid sx={{marginBottom:2}}>
                  {elc.link &&
                    <Link
                      target="_blank"
                      href={elc.link+"/pdf"}>
                      <Button
                          variant="contained"
                          size="small"
                          color={"boot_warning"}>
                        PDF
                      </Button>
                    </Link>
                  
                  }
                 {elc.whatsapp_link &&
                 
                 <Link
                  target="_blank"
                  href={elc.whatsapp_link}>
                  <Button
                          variant="outlined"
                          size="small"
                          color={'boot_success'}>
                          <WhatsAppIcon />
                  </Button>
                  </Link>
                 
                 
                 } 
            </Grid>

         
        </React.Fragment>
      </td>
        
		</tr>
    )
  }
	</tbody>
</table>



</section>

    </React.Fragment>

  );
}


export default DataGridImprovedTransactionList;
