import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
} from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Button as MuiButton,
  Card,
  Card as MuiCard,
  CardActions,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography as MuiTypography,
} from "@mui/material";
import { fontSize, spacing } from "@mui/system";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import {mascaraBoleto,formatCPFCNPJ} from "../../components/FormatacaoUtil"
import { useReactToPrint } from "react-to-print";
import LogoIcon from "../../assets/logo.webp";


function PayPaymentAccountModuloTituloComprovante({boletoData,setBoletoData}) {

  let { getApi,postApi } = useAuth();

const Typography = styled(MuiTypography)(spacing);
const [amountToPay, setAmountToPay] = useState(0);
let [isPrinting, setIsPrinting] = useState(false);



const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

const componentRefTable = useRef();

const handlePrint = useReactToPrint({
  content: () => componentRefTable.current,
  onBeforeGetContent : () => {
    return new Promise((resolve, reject) => {
      setIsPrinting(true);
      resolve();
    });
  },
  onAfterPrint : () => {
    return new Promise((resolve, reject) => {
      setIsPrinting(false);
      resolve();
    });
  }
});



useEffect(() => {
  if(boletoData){
    setAmountToPay(boletoData.total_amount);
  }

}, []);

  return (
    <React.Fragment>
       <Card sx={{ minWidth: 500 }}>
      <CardContent   ref={componentRefTable}>
        
      {isPrinting &&
        <div >
        <br></br>
      <center>
        <img src={LogoIcon} width={120}></img>
        - <Typography variant="subtitle2">Pagamento de contas</Typography>
      </center> 
      </div>
      
      }

        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
       
    
        <TableBody>
          {boletoData.barcode &&
          
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Código de Barras
              </TableCell>
              <TableCell component="th" scope="row">
               {mascaraBoleto(boletoData.barcode)}
              </TableCell>
          </TableRow>
          
          } 

        {boletoData.digitable_line &&
          
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Linha Digitável
              </TableCell>
              <TableCell component="th" scope="row">
               {mascaraBoleto(boletoData.digitable_line)}
              </TableCell>
          </TableRow>
          
          } 
        
          {boletoData.issuer.name &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Emissor
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.issuer.name}
              </TableCell>
          </TableRow>
          }
          
          {boletoData.payee_info.name &&
          
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              <TableCell component="th" scope="row">
                Favorecido
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.payee_info.name} -  {formatCPFCNPJ(boletoData.payee_info.document)}
              </TableCell>
          </TableRow>
        }
         {boletoData.payer_info.name &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Pagador
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.payer_info.name} -  {formatCPFCNPJ(boletoData.payer_info.document)}
              </TableCell>
          </TableRow>
          }
         {boletoData.due_date &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Vencimento
              </TableCell>
              <TableCell component="th" scope="row">
              {new Date(boletoData.due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}) }
              </TableCell>
          </TableRow>
          }
           {boletoData.total_amount &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Valor do Documento
              </TableCell>
              <TableCell component="th" scope="row">
               { parseFloat(boletoData.total_amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </TableCell>
          </TableRow>
          }
          {boletoData.discount>0 &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Valor do desconto
              </TableCell>
              <TableCell component="th" scope="row">
              { parseFloat(boletoData.discount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </TableCell>
          </TableRow>
          }
           {
           (boletoData.fine>0 || boletoData.interest_rate>0 ) &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Juros/Mora
              </TableCell>
              <TableCell component="th" scope="row">
              { parseFloat(boletoData.fine+boletoData.interest_rate).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </TableCell>
          </TableRow>
          }
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
               {[3,4].includes(boletoData.status.id) &&
               "Valor Pago"
               }
              </TableCell>
              <TableCell component="th" scope="row">
              { parseFloat(boletoData.paid_amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </TableCell>
          </TableRow>
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Data do pagamento
              </TableCell>
              <TableCell component="th" scope="row">
              {new Date(boletoData.release_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}) }
              </TableCell>
          </TableRow>
          {boletoData.description &&
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Descrição
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.description}
              </TableCell>
          </TableRow>
          }
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Status
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.status.description}
              </TableCell>
          </TableRow>
          {boletoData.additional_information.authorization_code &&
          
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Código de Autenticação
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.additional_information.authorization_code}
              </TableCell>
          </TableRow>
          }
          {boletoData.id &&
          
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              ID da Transação
              </TableCell>
              <TableCell component="th" scope="row">
              {boletoData.id}
              </TableCell>
          </TableRow>
          }
          
          
        </TableBody>
      </Table>
      <center>
        {
          !isPrinting &&
          <React.Fragment>
            <Button  size="medium" variant="contained" onClick={e=> handlePrint()}
            color="warning" >Imprimir
            </Button>
       
            <Button  size="medium" variant="contained" 
              onClick={e=>   window.location.href="/app/pagamento-de-contas"} color="info"
            >Realizar novo
            </Button>
          </React.Fragment>
        }
     
       
      </center>

    </TableContainer>
  
      </CardContent>
      
    </Card>
    </React.Fragment>
  );
}

export default PayPaymentAccountModuloTituloComprovante;
