import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import $  from 'jquery'
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Autocomplete
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";

import { useNavigate } from "react-router-dom";

function AddNewClient(props) {

  $('#contacomdigito_cad_transferencia').mask("#0-0", {reverse: true});

  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,businessAccount } = useAuth();
  let [loadAssociatedBankList, setLoadingAssociatedBankList] = useState(true);
  let [associatedBankList, setassociatedBankList] = useState([]);

  const Divider = styled(MuiDivider)(spacing);
  
  let [daysNotification, setDaysNotification] = useState(1);

  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose([]);
  };

  let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');


  let [typeNotification, setTypeNotification] = useState(2);

useEffect(() => {

},[props]);




const checkValues=()=>{
  if(!typeNotification){
    alert("Você precisa informar quando deve ser enviada as cobranças");
  }else if([2,3].includes(typeNotification) && !daysNotification){
    alert("Você precisa informar quando deve ser enviada as cobranças");
  }
  
else{
    return true;
  }
}

const handleSubmit = async (event) => {
  
  event.preventDefault();
  setSaving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSaving(false);
    setErrorMsg('')
    return false;
  }

  let condition;
  if([1].includes(typeNotification)){
    condition={
      created:0,
      payment_status:0
    }
  }else if([2].includes(typeNotification)){
    condition={
      overdue:daysNotification,
      payment_status:0
    }
  }else if([3].includes(typeNotification)){
    condition={
      beforedue:daysNotification,
      payment_status:0
    }
  }else if([4].includes(typeNotification)){
    condition={
      payment_status:0
    }
  }else if([5].includes(typeNotification)){
    condition={
      payment_status:1
    }
  }else if([6].includes(typeNotification)){
    condition={
      payment_status:3
    }
  }
   let response = await postApi("/automation/whatsapp/create",{
    type_id:typeNotification,
    condition:condition
    


   });
 
  response_run(response);

};
const navigate = useNavigate();


const response_run=(response)=>{
  if(response.success){
    alert("Automação via Whatsapp criada com sucesso.");
    window.location.reload(false);
  }else{

    if(response.type_id){
      setErrorMsg(response.type_id.toString())
    }else if(response.condition.payment_status){
      setErrorMsg(response.condition.payment_status.toString())
    }else if(response.condition.overdue){
      setErrorMsg(response.condition.overdue.toString())
    }else if(response.condition.created){
      setErrorMsg(response.condition.created.toString())
    }else if(response.condition.beforedue){
      setErrorMsg(response.condition.beforedue.toString())
    }else{
      setErrorMsg( response.error)
    }
    setSaving(false)
  }
}




  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="simple-dialog-title" ><center>Crie uma nova automação de Whatsapp</center></DialogTitle>
       <Typography variant="body1" align="center" >Envie notificações de cobranças aos seus clientes.</Typography>
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >
            
            <CardContent>

            <Grid container spacing={6}>
                <Grid item md={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Quando deve ser enviada?</FormLabel>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={typeNotification}
                    onChange={e=> setTypeNotification(parseInt(e.target.value))} >
                    <FormControlLabel value={5} control={<Radio />} label="Cobrança paga" />
                    <FormControlLabel value={1} control={<Radio />} label="Cobrança criada" />
                    <FormControlLabel value={3} control={<Radio />} label="Antes do vencimento" />
                    <FormControlLabel value={4} control={<Radio />} label="No mesmo dia do vencimento" />
                    <FormControlLabel value={2} checked={typeNotification==2} control={<Radio />} label="Depois do vencimento" />
                    <FormControlLabel value={6} control={<Radio />} label="Cobrança cancelada" />
                  </RadioGroup>
                </FormControl>
                </Grid>
              </Grid>
              {
                [2,3].includes(parseInt(typeNotification)) &&
                <React.Fragment>
                  
                  <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Quantidade de dias {typeNotification==2&& "depois de vencer"}  {typeNotification==3&& "antes de vencer"}</label>
                  <TextField
                    id="businessName"
                    placeholder={"Nome ou razão social"}
                    variant="outlined"
                    value={daysNotification}
                    onChange={e => setDaysNotification(
                      e.target.value?
                      parseInt(e.target.value)<=0?1:parseInt(e.target.value):0
                      )}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
              
              
                </React.Fragment>
                }
              
              <Divider my={3} ></Divider>

              <Typography color="primary" variant="h7" >Ação: </Typography>
                {typeNotification==1 &&
                  "A notificação de cobrança será enviada no momento em que a cobrança for criada"
                
                }
                {typeNotification==2 &&
                  "A notificação de cobrança será enviada "+daysNotification+ " dia após o vencimento."
                }
             
                {typeNotification==3 &&
                  "A notificação de cobrança será enviada "+daysNotification+ " dia antes do vencimento."
                }

                {typeNotification==4 &&
                  "A notificação de cobrança será enviada no mesmo dia do vencimento."
                }

                {typeNotification==5 &&
                  "Será enviada uma notificação com a confirmação do pagamento."
                }

                {typeNotification==6 &&
                  "Será enviada uma notificação com informativo de cobrança cancelada."
                }


<Divider my={3} ></Divider>
            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
              <br></br>
              <Button variant="contained" disabled={saving} color="primary" mt={3} onClick={handleSubmit} >
                Salvar informações
              </Button>
              
              
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                




    </Dialog>
  );
}


export default AddNewClient;
