import { Book } from "@mui/icons-material";
import {
  Briefcase,
  Grid,
  DollarSign,
  PhoneCall,
  Folder,
  Cloud,
  Edit,
  Home,
  UserCheck,
  Server,
  Layers,
  Map,
  Monitor,
} from "react-feather";
import { FiHeart,FiPhoneCall,FiKey, FiPower, FiTool, FiShoppingCart, FiBookOpen } from "react-icons/fi";

const pagesSection = [
  {
    href: "/app",
    icon: Home,
    title: "DashBoard"
  },
  {
    href: "#",
    icon: Edit,
    title: "Meus Clientes",
    children: [
      {
        href: "#addNewClient",
        title: "Cadastrar um novo",
      },
      {
        href: "/meus-clientes",
        title: "Lista de Clientes",
      },
      {
        href: "/meus-clientes/inadimplentes",
        title: "Inadimplentes",
      }
    ],
  },
  {
    href: "#",
    icon: Briefcase,
    title: "Cobranças",
    children: ['995b8aad-97c1-492c-aa03-a45cf8a1645a',
  '26632'].includes(localStorage.getItem('@CacheBank_BusinessAccount'))?
    [
      {
        href: "/cobrancas/#",
        title: "Todas cobranças",
      },
      {
        href: "/cobrancas/carnes",
        title: "Carnês de boletos",
      },
      {
        href: "/cobrancas/scanner/boleto",
        title: "Pesquisar BarCode",
      },
      
    ]:[
      {
        href: "/cobrancas",
        title: "Todas cobranças",
      },
      {
        href: "/cobrancas/carnes",
        title: "Carnês de boletos",
      },
    ]
  }];
  const MeuDinheiroSection = [
  {
    href: "#",
    icon: DollarSign,
    title: "Transferências",
    children: [
      {
        href: "/transferencias/contas-bancarias",
        title: "Contas Bancárias",
      },
      {
        href: "/transferencias",
        title: "Extrato de Saques",
      }
    ],
  },
  {
    href: "/recarga",
    icon: FiPhoneCall,
    title: "Recarregue seu celular (cashback)"
  },
  {
    href: "/app/pagamento-de-contas",
    icon: Layers,
    title: "Pagamento de contas"
  },
  {
    href: "/app/extrato",
    icon: FiBookOpen,
    title: "Extrato da conta"
  }
];


const configContaSection = [
  {
    href: "#",
    icon: Folder,
    title: "Minha Conta",
    children: [
      {
        href: "/minha-conta/meus-dados",
        title: "Dados da conta",
      },
      {
        href: "/minha-conta/detalhes",
        title: "Detalhes da Conta",
      },
      {
        href: "/minha-conta/documentos",
        title: "Meus Documentos",
      }
    ],
  },
  {
    href: "#",
    icon: UserCheck,
    title: "Acessos (breve)",
    children: [
      {
        href: "#",
        title: "Niveis de acesso (breve)",
      },
      {
        href: "#",
        title: "Operadores (breve)",
      }
    ],
  }
];


const FerramentasModulosSection = [
  {
    href: "/components",
    icon: PhoneCall,
    title: "Automação",
    children: [
      {
        href: "/automacao/email",
        title: "Automação via E-mail",
      },
      {
        href: "/automacao/sms",
        title: "Automação via SMS",
      },
      {
        href: "/automacao/whatsapp",
        title: "Automação via Whatsapp",
      },
      {
        href: "/automacao/robo-voz",
        title: "Robô de voz",
      },
      {
        href: "/automacao/enviadas",
        title: "Automações enviadas",
      }
    ],
  },
  {
    href: "/components",
    icon: Cloud,
    title: "Portal do cliente",
    children: [
      {
        href: "/portal-do-cliente/atribuir-acesso",
        title: "Atribuir Acesso",
      },
      {
        href: "/portal-do-cliente/clientes-associados",
        title: "Clientes associados",
      },
      {
        href: "/portal-do-cliente/configuracao",
        title: "Configuração",
      }
    ],
  },
   {
     href: "#",
     icon: FiKey,
     title: "Integração API",
     children: [
       {
         href: "/integracao/acesso",
         title: "Chaves de Acesso",
       }
     ],
   },
];


const AtendimentoAoClienteSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Solicitações",
    children: [
      {
        href: "/components/alerts",
        title: "Abrir uma nova",
      },
      {
        href: "/components/accordion",
        title: "Ver solicitações",
      }
    ],
  },
  {
    href: "/components",
    icon: Grid,
    title: "Meu Consultor"
  }
];


const navItems = [
  {
    title: "DashBoard",
    pages: pagesSection,
  },
  
  {
    title: "Meu Dinheiro",
    pages: MeuDinheiroSection,
  },
  {
    title: "Configuração da Conta",
    pages: configContaSection,
  },
  {
    title: "Módulos e Ferramentas",
    pages: FerramentasModulosSection,
  },

];

export default navItems;
