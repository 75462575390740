import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Grid, Link, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import useAuth from "../../hooks/useAuth";
import { Navigate, useParams } from "react-router-dom";
import { setBusinessAccount, setSession } from "../../utils/jwt";


function SignInSecret() {
  const { isAuthenticated, isInitialized } = useAuth();
  let { access_token,business_account_id } = useParams();

  useEffect(() => {
  
    setBusinessAccount(business_account_id);
    setSession(access_token);
    window.location.href="/app";
    return ;
  }, [isAuthenticated,isInitialized]);

  

  return (
    <React.Fragment>
      
      
    </React.Fragment>
  );
}

export default SignInSecret;
