import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import EditClient from "./edit/index"
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi ,deleteApi} = useAuth();
const [listClients, setListClients] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);


const [statusCostumer, setStatusCostumer] = React.useState(-1);


const [saving, setSaving] = React.useState(false);



const [page, setPage] = React.useState(0);
const [limitPage, setLimitPage] = React.useState(5);
const [totalRecords, setTotalRecords] = React.useState(0);

const [openEditClient, setOpenEditClient] = React.useState(false);
const [selectedEditValue, setSelectedEditValue] = React.useState([]);
const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const columns = [
  { field: "id", headerName: "ID", width: 150,  hide: true },
  {
    field: "reference",
    headerName: "Referência",
    width: 200,
    editable: false,
  },
  {
    field: "fullname",
    headerName: "Nome Completo",
    width: 200,
    editable: false,    
    sortable: false,
  },
  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    width: 150,
    editable: false,    
    sortable: false,
  },
  {
    field: "email",
    headerName: "E-mail",
    width: 250,
    editable: false,    
    sortable: false,
  },
  {
    field: "phone",
    headerName: "Telefone",
    editable: false,    
    sortable: false,
    width: 140
  },
  {
    field: "Status",
    headerName: "Status",
    editable: false,    
    sortable: false,
    width: 140,
    renderCell: (params) => {
      let status=params.row.status;

      return <div className="rowitem">{status.name}</div>;
    }
  },
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 400,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          disabled={saving}
          color={"primaryboot"}
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e => navigate("/meus-clientes/visualizar/"+params.row.id)}>
          Visualizar
        </Button>

        {
          [1].includes(params.row.status.id) &&
          <Button
          disabled={saving}
          variant="contained"
          size="small"
          color="success"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e=> handleClickOpenEditClient(params.row)}>
          Alterar Senha
        </Button>
        }

       

        {
          [0,2].includes(params.row.status.id) &&
          <Button 
          disabled={saving}
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginLeft: 16 }}
          onClick={e=>UpdatePermissionStatus(true,params.row.id,params.row.status )}
          tabIndex={params.hasFocus ? 0 : -1} >Permitir Acesso
        </Button>
        }
        {
          [1].includes(params.row.status.id) &&
          <Button
          disabled={saving}
          variant="contained"
          size="small"
          color="error"
          style={{ marginLeft: 16 }}
          onClick={e=>UpdatePermissionStatus(false,params.row.id,params.row.status)}
          tabIndex={params.hasFocus ? 0 : -1} >Bloquear Acesso
        </Button>
        }
      </React.Fragment>
    
        
    ),
  },
];



const [query_type, setQueryType] = React.useState("fullname");
const [searchText, setSearchText] = React.useState(null);

useEffect(() => {

  LoadMyClients();
},[]);


function LoadQueryParams(limit_arg,page_arg){

  let invoiceTmp={
    "querycomplete":true,
    "queryString":{
      "costumer":{}
    },

  };
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["minimal_data"]=true;


  if(statusCostumer>=0){
    invoiceTmp["queryString"]["costumer"]["status"]= statusCostumer;
    

  }


   

   // Filtro de usuario
   if(["fullname", "tax_identifier", "email", "phone"].includes(query_type)){
     
     if(query_type=="fullname"){
       invoiceTmp["queryString"]["costumer"]["fullname"]=searchText;
     }else if(query_type=="tax_identifier"){
       invoiceTmp["queryString"]["costumer"]["tax_identifier"]=searchText;
     } else if(query_type=="email"){
       invoiceTmp["queryString"]["costumer"]["email"]=searchText;
     }else if(query_type=="phone"){
       invoiceTmp["queryString"]["costumer"]["phone"]=searchText;
     }else if(query_type=="reference"){
      invoiceTmp["queryString"]["costumer"]["reference"]=searchText;
    }else if(query_type=="id"){
      invoiceTmp["queryString"]["costumer"]["id"]=searchText;
    }
   }




  return invoiceTmp;

}


const LoadMyClients = async (page_arg=null, limit_arg=null) => {
    
  setListClients([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));

  let response = await postApi("/costumer/list/querycomplete", Arrayp);
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListClients(response.lista.map(x=> x.costumer));
  setLoadingList(false);

};



const UpdatePermissionStatus = async (allow, costumerId, status) => {
  if(allow && !window.confirm("O acesso a este usuário será liberado, você confirma?")){
    return ;
  }else if(!allow && !window.confirm("O acesso a este usuário será bloqueado, você confirma?")){
    return ;
  }

  setSaving(true)
   let response = await postApi("/allstore/update/status/users",
   {
     costumer_list:[costumerId],
    allow:allow
   });

  if(allow){
    status.id=1;
    status.name="Autorizado"
  }else{
    status.id=2;
    status.name="Bloqueado"
  }

  setSaving(false)
};



  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
      <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="query"
                    placeholder={query_type=="fullname"&&"Nome do cliente"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item md={4}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de pesquisa</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"fullname"}>Nome do cliente</MenuItem>
                          <MenuItem value={"tax_identifier"}>CPF/CNPJ</MenuItem>
                          <MenuItem value={"email"}>E-mail</MenuItem>
                          <MenuItem value={"reference"}>Referência</MenuItem>
                          <MenuItem value={"phone"}>Telefone</MenuItem>
                        
                      </Select>
                    </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Status"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        value={statusCostumer}
                        onChange={e=> setStatusCostumer(e.target.value)}
                      >
                      <MenuItem value={-1}>Todos os Status </MenuItem>
                      <MenuItem value={1}>Acesso permitido</MenuItem>
                      <MenuItem value={2}>Acesso bloqueado</MenuItem>
                      <MenuItem value={0}>Sem acesso definido</MenuItem>
                     
                      </Select>
                    </FormControl>
                </Grid>
                </Grid>
              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadMyClients(0)}>
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
        <div style={{ height: (limitPage==5?400:600), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[5, 10, 15,20,50,100]}
            rows={listClients}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}
            
          />
          
<EditClient
            selectedValue={selectedEditValue}
            open={openEditClient}
            onClose={handleCloseEditClient}
          />
    
        </div>
      </Paper>
    </Card>
  );
}

  function DataGridPage() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);
    };





  return (
    <React.Fragment>
      <Helmet title="Meus Clientes" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Associação de clientes
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="#">
          Associação de clientes
        </Link>
        <Typography>Listagem</Typography>
      </Breadcrumbs>
      </Grid>

      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />

       
       
    </React.Fragment>
  );
}

export default DataGridPage;
