import React from "react";
import {formatBoleto,formatCPF,formatCNPJ } from '@brazilian-utils/brazilian-utils';

export  function mascaraBoleto(codigoBarras) {
 return formatBoleto(codigoBarras)
  
}


export  function formatCPFCNPJ(value) {
  let cpfcnpj=String(value).replace(/\D/g, "");
  if(cpfcnpj.length==11){
    return formatCPF(cpfcnpj, {pad:true})
  }else {
    return formatCNPJ (cpfcnpj, {pad:true})

  }
 }
  
