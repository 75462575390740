import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Link from '@mui/material/Link';
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from 'react';
import UpdateDueDate from "../../Cobrancas/update-due"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import UpdateStatusCobMarkedPaid from "../../Cobrancas/update-marked-as-paid"
import DataGridImprovedTransactionList from './DataGridImprovedTransactionList';
export default function BasicTable({blockAction,setBlockAction,invoices}) {
    const navigate = useNavigate();

let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();
  
const [openUpdateDue, setopenUpdateDue] = useState(false);
const [selectedValue, setSelectedValue] = useState(false);
const [openUpdateStatusMarkedPaid, setOpenUpdateStatusMarkedPaid] = useState(false);

const handleCloseInvoiceUpdateDue = (value) => {
  setopenUpdateDue(false);
  invoices.find(x=> x.id=value.id).due_date=value.due_date;
};

const handleCloseInvoiceUpdateMarkedPaid = (value) => {
  setOpenUpdateStatusMarkedPaid(false);
  invoices.find(x=> x.id=value.id).due_date=value.due_date;
  invoices.find(x=> x.id=value.id).status=value.status;
  invoices.find(x=> x.id=value.id).amount_marked_paid=value.amount_marked_paid;
};

const HandleOpenUpdateDueDate = (value) => {
    setSelectedValue(value);
    setopenUpdateDue(true)
  };

  const HandleOpenUpdateMarkedPaid = (value) => {
    setSelectedValue(value);
    setOpenUpdateStatusMarkedPaid(true)
  };


    const ChangeStatus = async (status,invoice) => {
        if (!window.confirm("Você realmente deseja fazer isso?")) {
          return ;
        }
        setBlockAction(true);
        let response = await postApi("/invoice/marcar/"+status+"/"+invoice.id,{});
        if(response.error){
          alert(response.error.toString());
        }else{
          let cob=invoice;
          if(status=="pago"){
            invoice["status"]["id"]=7;
            invoice["status"]["name"]="Marcado como Pago";
            invoice["status"]["extended_name"]="Marcado como Pago";
         
          }else  if(status=="cancelado"){
            invoice["status"]["id"]=3;
            invoice["status"]["name"]="Cancelada";
            invoice["status"]["extended_name"]="Transação Cancelada";
           
    
          }
        }
        setBlockAction(false)
      };

      
  return (
    <React.Fragment>
    
    <DataGridImprovedTransactionList
    invoices={invoices}
    HandleOpenUpdateDueDate={HandleOpenUpdateDueDate}
    ChangeStatus={ChangeStatus}
    navigate={navigate}
    blockAction={blockAction}
    WhatsAppIcon={WhatsAppIcon}
    HandleOpenUpdateMarkedPaid={HandleOpenUpdateMarkedPaid}
    />

<UpdateDueDate
invoice={selectedValue}
open={openUpdateDue}
onClose={handleCloseInvoiceUpdateDue}
/>

<UpdateStatusCobMarkedPaid
            invoice={selectedValue}
            open={openUpdateStatusMarkedPaid}
            onClose={handleCloseInvoiceUpdateMarkedPaid}
          />


</React.Fragment>
  );
}