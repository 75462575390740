import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import TblItensCreateBoletoDestkop from "../createboleto/TblItensCreateBoleto"
import TblItensCreateBoletoMobile from "../createboleto/TblItensCreateBoletoMobile"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {ptBR} from 'date-fns/locale'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
  OutlinedInput
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.subtractDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
}


function CreateBoleto(props) {

  const navigate = useNavigate();


  

  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();
 
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  
  let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');
  
  let [listCostumer, setListCostumer] = useState([]);
  let [loadlistCostumer, setLoadingListCostumer] = useState(false);

  let [identifier, setIdentifier] = useState('');
  let [fullname, setFullname] = useState('');
  let [email, setEmail] = useState('');

  let [costumer_id, setCostumerId] = useState(null);
  let [phone, setPhone] = useState('');



  let [reference, setReference] = useState('');

  let [createdBoleto, setCreatedBoleto] = useState(false);
  let [responseBoleto, setResponseBoleto] = useState({});


  
  let [due_date, setDueDate] =  useState(   dayjs(new Date()),
  );


  let [applyFees, setApplyFees] = useState(false);
  let [deadlineDays, setDeadlineDays] = useState(0);
  let [fine_modality, setFineModality] = useState("percent");
  let [interest_modality, setInterestModality] = useState("percent");
  let [fine_value, setFineValue] = useState(null);
  let [interest_value, setInterestValue] = useState(null);


  
  let [discount_modality, setDiscountModality] = useState("percent");
  let [discount_value, setDiscountValue] = useState(null);
  let [discount_goal_date, setDiscountGoalDate] = useState(null);

  const setValorDesconto= (val) =>{

    let discount_valueTMP

    discount_valueTMP = String(val).replace(/\D/g, "");
  
    discount_valueTMP=discount_valueTMP/100;
    setDiscountValue(discount_valueTMP)
  }

  const setTFineValue= (val) =>{

    let fine_value_valueTMP

    fine_value_valueTMP = String(val).replace(/\D/g, "");
  
    fine_value_valueTMP=fine_value_valueTMP/100;
    setFineValue(fine_value_valueTMP)
  }

  const setTInterestValue= (val) =>{

    let interest_value_valueTMP

    interest_value_valueTMP = String(val).replace(/\D/g, "");
  
    interest_value_valueTMP=interest_value_valueTMP/100;
    setInterestValue(interest_value_valueTMP)
  }

  let [applyDiscount, setApplyDiscount] = useState(false);
  


const ChooseClient= (e,value)=>{
  if(!value){
    setCostumerId('')
  setFullname('')
  setIdentifier('')
  setEmail('')
  setPhone('')
  setPhone('')
  return ;
  }
  setCostumerId(value.id)
  setFullname(value.fullname)
  setIdentifier(value.tax_identifier)
  setEmail(value.email)
  setPhone(value.phone)
  setPhone(value.phone)
}

const LoadMyClients = async (fullnameArgs) => {
  if(fullnameArgs){
    if(fullnameArgs==0){
      return ;
    }
    if(fullnameArgs.length<3){
      return ;
    }
  }else{
    return ;
  }

  setListCostumer([]);
  setLoadingListCostumer(true);
  let response = await getApi("/costumer/list",{
      limit:400,
      page:0,
      query_type:"fullname",
      queryString:fullnameArgs?fullnameArgs:searchFullName
  });
  
  setListCostumer(response.lista.map(x=> x.costumer));
  setLoadingListCostumer(false);

};


let [itensCobranca, setItensCobranca] = useState([]);


const response_run=(response)=>{
  setSaving(false);
  if(response.success){
    setCreatedBoleto(true);
    setResponseBoleto(response.invoice)
  
  }else{
    if(response.costumer_id){
      setErrorMsg(response.costumer_id.toString())
    }else if(response.due_date){
      setErrorMsg(response.due_date.toString())
    }
    else if(response.reference){
      setErrorMsg(response.reference.toString())
    }
    else if(response.itens){
      setErrorMsg(response.itens.toString())
    }else if(response.discount){
      setErrorMsg(response.discount.toString())
    }else if(response.discount){
      if(response.discount.modality){
        setErrorMsg(response.discount.modality.toString())
      }
      if(response.discount.value){
        setErrorMsg(response.discount.value.toString())
      }
    
    }else if(response['discount.goal_date']){
      setErrorMsg(response['discount.goal_date'].toString())
    }else if(response.fine){
      if(response.discount.deadline){
        setErrorMsg(response.fine.deadline.toString())
      }
      if(response.discount.fine_modality){
        setErrorMsg(response.fine.fine_modality.toString())
      }
      if(response.discount.fine_value){
        setErrorMsg(response.fine.fine_value.toString())
      }
      if(response.discount.interest_modality){
        setErrorMsg(response.fine.interest_modality.toString())
      }
  
    }
    else if(response["itens.0.description"]){
      setErrorMsg("A descrição do item precisa ser preenchida")
    }else if(response["itens.0.qtd"]){
      setErrorMsg("A quantidade do item precisa ser preenchida")
    }
    else if(response.error){
      setErrorMsg( response.error.toString())
    }else{
      setErrorMsg( "Ocorreu um erro, tente novamente.")
    }
    setSaving(false)
  }
}



const checkValues= ()=>{
  let valorTotal=itensCobranca.reduce((prev, curr) => (curr.qtd*curr.amount)+prev, 0);

  let itemsEmpty=itensCobranca.reduce((prev, curr) => ( 
    (curr.qtd<1 || curr.description=='' ) && 1   )+prev, 0)

  if(valorTotal<7){
    alert("O valor minimo para emitir um boleto é de R$ 7,00");
  }else if(!costumer_id){
    alert("Você precisa especificar um cliente");
  }else if(applyFees && fine_value<=0 || fine_value>20){
    alert("A multa precisa ter de 0.01% até 20%");
  }else if(applyFees && interest_value<0.01 || interest_value>20){
    alert("O juros ao mês precisa ter de 0.01% até 20%");
  }else if(applyFees && ( !interest_value || !interest_value)){
    alert("Os juros e multa devem ser preenchidos");
  }else if(!due_date){
    alert("O vencimento precisa definido.");
  }
  else if(applyDiscount && discount_modality=='percent' && ( discount_value>95 || discount_value<0.01)){
    alert("Você não pode colocar esta porcentagem de desconto");
  }else if(applyDiscount && discount_modality=='fixedvalue' && ( discount_value>=valorTotal || discount_value<=0.01)){
    alert("O desconto precisa ser menor que o valor total ou maior que R$ 0.01");
  }else if(applyDiscount && ( !discount_value)){
    alert("O valor do desconto deve ser preenchido");
  }else if(itemsEmpty>0){
    alert("Os itens do pedido contém valores não preenchidos ou inválidos.");
  }
  else{
    return true;
  }
}





const HandleSubmit = async () => {
  setErrorMsg(null)
  setSaving(true)
  if(!checkValues()){
    setSaving(false)
    return false;
  }

  let submitPost={
    costumer_id: costumer_id,
    reference:reference,
    itens:itensCobranca,
    due_date:due_date.toISOString().substr(0,10)
  };
  if(applyFees==true){
    let fine_TMP={
      fine_modality:fine_modality,
      fine_value:parseFloat(fine_value),
      interest_modality:interest_modality,
      interest_value:parseFloat(interest_value),
      deadline:new Date(due_date).addDays(parseInt(deadlineDays)).toISOString().substr(0,10),
    }
    submitPost.fine=fine_TMP;
  }

  if(applyDiscount==true){
    let discount_TMP={
      modality:discount_modality,
      value:parseFloat(discount_value),
      goal_date:new Date(due_date).subtractDays(parseInt(discount_goal_date)).toISOString().substr(0,10),
    }
    submitPost.discount=discount_TMP;
  }


  let response = await postApi("/invoice/boleto/create",submitPost);
  response_run(response);
}


const copiarboleto=()=>{
  let copyText = document.getElementById("barcode_createBoleto");
  copyText.select();
  copyText.setSelectionRange(0, 99999)
  document.execCommand("copy");
  alert("Código de barras copiado com sucesso!");
}

const windowSize = useRef([window.innerWidth, window.innerHeight]);

const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
const [options, setOptions] = React.useState([]);
const loading = open && options.length === 0;

React.useEffect(() => {
  if (!openAutocomplete) {
    setListCostumer([]);
  }
}, [openAutocomplete]);



React.useEffect(() => {
  let active = true;

  if (!loading) {
    return undefined;
  }

  (async () => {

    if (active) {
      LoadMyClients(searchFullName);
    }
  })();

  return () => {
    active = false;
  };
}, [loading]);

let [searchFullName, setSearchFullName] = useState('');


  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      style={{height:"94%", width:windowSize.current[0]}}
      maxWidth={createdBoleto?"sm":window.isNativeApp?"lg":"md"}
      
    >
      <DialogTitle id="simple-dialog-title" ><center>{createdBoleto?"Seu boleto foi emitido com sucesso!":"Emissão de boleto"}</center></DialogTitle>
       
    {createdBoleto==false &&
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

              <Grid container spacing={6} >
                <Grid item md={12} 
                style={{width:window.isNativeApp&&"100%"}}>
                 <Autocomplete
                open={openAutocomplete}
                onOpen={() => {
                  setOpenAutocomplete(true);
                }}
                onClose={() => {
                  setOpenAutocomplete(false);
                }}
                  id="country-select-demo"
                  fullWidth
                  
                  options={listCostumer}
                  loading={loadlistCostumer}
                  autoSelect
                  getOptionLabel={(option) => option.fullname+" - "+option.tax_identifier + " ("+option.id+")"}
                  onChange={ChooseClient}
                  onInputChange={ (e,native)=> {setSearchFullName(native); LoadMyClients(native) }}
                  renderOption={(props, option) => (
                    <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.fullname} - {option.tax_identifier}
                    </Box>
                  )}
                  
                  noOptionsText={searchFullName.length>3?"Não encontrado":"Digite as 3 primeiras letras"}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Digite o nome do cliente"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(searchFullName.length<=3 && !costumer_id  ) ?<Typography variant="overline">Começe digitando as 3 primeiras letras</Typography> : 
                            ( loadlistCostumer ? <CircularProgress color="inherit" size={20} /> : null)
                            }
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              {loadlistCostumer &&
                <LinearProgress />}
                </Grid>
              </Grid>
              
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Endereço de e-mail *</label>
                  <TextField
                    id="fullname"
                    placeholder={"E-mail"}
                    variant="outlined"
                    value={email}
                    fullWidth
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    my={2}
                    type="email"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item sx={{width:"100%"}}>
                <label for="my-input">Telefone *</label>
                <InputMask
                  mask={ ( String(String(phone).replace(/\D/g, "")).substring(2,3) >=5 )?"(99) 9 9999-9999":"(99) 9999-9999"}
                  maskChar="_"
                  placeholder="Telefone"
                  value={phone}
                  disabled={true}
                  fullWidth
                  my={2}
                  onChange={e => setPhone(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="phone"
                  type="text"
                  id="phone"
                  disabled={true}
                  autoComplete="phone"
                  placeholder="Telefone"
                />}
                </InputMask>
                </Grid>
              </Grid>
              
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Referência da cobrança</label>
                  <TextField
                    id="reference"
                    placeholder={"Referência da cobrança"}
                    variant="outlined"
                    value={reference}
                    onChange={e => setReference(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={12}>
                {window.isNativeApp?
                  <TblItensCreateBoletoMobile setItensCobranca={setItensCobranca} itensCobranca={itensCobranca}/>
                  :
                  <TblItensCreateBoletoDestkop setItensCobranca={setItensCobranca} itensCobranca={itensCobranca}/>
                  }
                  
                
                </Grid>
              </Grid>
               
              <Grid container spacing={6}>
                <Grid item md={12}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Data de Vencimento"
                    inputFormat="dd/MM/yyyy"
                    onChange={setDueDate}
                    value={due_date}
                    minDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  
                </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={applyFees}
                      onChange={e=> setApplyFees(!applyFees)}
                      name="gilad"
                    />
                  }
                  label="Aplicar multa após o vencimento"
                />
              </Grid>
            
            </Grid>
         
            
            {applyFees &&
              <Grid container spacing={6}>
                <Grid item md={3}>
                  <FormControl  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Multa</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      label="Multa"
                      
                      type={'number'}
                      step={0.01}
                      max={20}
                      onChange={e=> setTFineValue(e.target.value)}
                      value={parseFloat(fine_value).toFixed(2)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Juros ao mês</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      label="Juros ao mês"
                      type={'number'}
                      step={0.01}
                      max={20}
                      onChange={e=> setTInterestValue(e.target.value)}
                      value={parseFloat(interest_value).toFixed(2)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            }


            
            <Grid container spacing={6}>
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Switch
                    checked={applyDiscount}
                    onChange={e=> setApplyDiscount(!applyDiscount)}
                      name="gilad"
                    />
                  }
                  label="Aplicar desconto antes de vencer"
                />
              </Grid>
            </Grid>
            {applyDiscount &&
              <Grid container spacing={6}>
                <Grid item md={3}>
                <FormControl sx={{ m: 1, width: '25ch' }}  >
                  <InputLabel id="demo-simple-select-label">Tipo de Desconto</InputLabel>
                    <Select fullWidth required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Tipo de Desconto"
                      onChange={e=> setDiscountModality(e.target.value)}
                      value={discount_modality}
                    >
                      <MenuItem value={'percent'}>Porcentagem</MenuItem>
                      <MenuItem value={'fixedvalue'}>Valor Fixo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">{discount_modality=="percent"?'%':'Reais'} de desconto</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                      <InputAdornment position="end">
                        {discount_modality=="percent"?'%':'reais'}
                        </InputAdornment>}
                      label="Juros ao mês"
                      type={'number'}
                      step={0.01}
                      max={20}
                      onChange={e=> setValorDesconto(e.target.value)}
                      value={discount_modality=="fixedvalue"?parseFloat(discount_value).toFixed(2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):parseFloat(discount_value).toFixed(2)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Dias antes de vencer</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={<InputAdornment position="end">dias</InputAdornment>}
                      label="Dias antes de vencer"
                      type="number"
                      step={1}
                      max={60}
                      onChange={e=> setDiscountGoalDate(e.target.value)}
                      value={discount_goal_date}
                    />
                    <small>Definir <strong>0</strong> = Desconto até o vencimento</small>
                  </FormControl>
                </Grid>
              </Grid>
            }
            

          
              <br></br>
              <Grid container justifyContent="left" >
                <Grid item>
                    <Typography variant="subtitle1" >Valor Total: </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" >{parseFloat( itensCobranca.reduce((prev, curr) => (curr.qtd*curr.amount)+prev, 0) ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }</Typography>
                </Grid>
              </Grid>


              <Grid container justifyContent="right" >
                <Grid item>
                    <Button variant="contained" onClick={handleClose} disabled={saving} color="boot_secondary" mt={3} >
                      Cancelar
                    </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={HandleSubmit} disabled={saving} color="boot_primary" mt={3} >
                    Gerar Boleto
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
              
              
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                

    }
    {/* Inicio Exibição do boleto criado */}
    {createdBoleto==true &&
    
    <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <Typography variant="subtitle1" color="textPrimary" align="center">
                    Compartilhe o código de barras com o seu cliente
                  </Typography>
                  
                  <TextField
                    id="barcode_createBoleto"
                    placeholder={"Código de Barras"}
                    variant="outlined"
                    inputProps={{min: 0, style: { textAlign: 'center' }}}
                    value={responseBoleto.id?responseBoleto.boleto["digitable_line"]:"Não foi gerado."}
                    fullWidth
                    readOnly={true}
                    onClick={copiarboleto}
                    my={2}
                    type="text"
                  />
                  
                  
                </Grid>
                <Grid item md={12} justifyContent={"center"} >
                  <Typography variant="subtitle1" color="textPrimary" align="center">
                    Ou compartilhe o link do boleto
                  </Typography>
                  <br></br>
                  <Box textAlign='center' >
                    <Link
                      href={responseBoleto.boleto['url_slip']}
                        target="_blank">
                    <Button
                       variant="contained"
                       size="medium"
                       color={'boot_secondary'}  
                    >
                    Acessar Boleto
                    </Button>
                    </Link>

                    <Link  href={responseBoleto.boleto['url_slip_pdf']} target="_blank">
                      <Button variant="contained" size="medium" color={'boot_warning'}>Baixar PDF</Button>
                    </Link>
                  </Box>
                  
                  
                </Grid>
                <Grid item md={12} justifyContent={"center"} >
                 
                  <Box textAlign='center' >
                  <Link  href={responseBoleto.whatsapp_link} target="_blank">
                    <Button variant="contained" size="medium"  color={'boot_success'}>
                      <WhatsAppIcon />
                    </Button>
                  </Link>
                  </Box>
                  
                  
                </Grid>
              </Grid>
            </CardContent>
        </Card>
        </Grid>
    </Grid>
    }

    </Dialog>
  );
}


export default CreateBoleto;
