import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AlertTitle,
  Button,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "../../../assets/styles/datagrid-improved.css"
import { Box } from "@mui/system";


function DataGridImprovedCobList({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading
}) {


  return (
    <React.Fragment>

          
{window.isNativeApp && 
loading && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}

    {!window.isNativeApp &&
        <DesktopTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        
      />
      }

    {window.isNativeApp &&
        <MobileTable
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        
      />
      }

    </React.Fragment>

  );
}


function DesktopTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading
}) {
  return (
    <React.Fragment>
        <DataGrid
        rowsPerPageOptions={rowsPerPageOptions}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        paginationMode={'server'}
        onPageChange={onPageChange }
        loading={loading}
        
      />
      

    </React.Fragment>

  );
}

function MobileTable({
  rowsPerPageOptions,rows,columns,pageSize,rowCount,page,onPageSizeChange,
  onPageChange,loading
}) {
  const navigate = useNavigate();
  const ColorButtonTransaction =(status)=>{
    let color="primary";
    if(status==0){
      color="boot_warning"
    }else if(status==1){
      color="boot_success"
    }else if(status==2){
      color="boot_info"
    }else if(status==3){
      color="boot_secondary"
    }else if(status==4){
      color="boot_dark"
    }else if(status==5){
      color="boot_danger"
    }else if(status==6){
      color="boot_dark"
    }else if(status==7){
      color="boot_success"
    }else if(status==8){
      color="boot_success"
    }else if(status==9){
      color="boot_success"
    }else if(status==10){
      color="boot_secondary"
    }
  
    return color;
  }
 

   //  console.log( Object.getOwnPropertyNames(row));
     // console.log(row);
   
  
  return (
    <React.Fragment>

    <section id="no-more-tables">
    
<table>
	<thead>
		<tr>
      {columns.map(el => 
      	<th>{el.headerName}</th>
      )
    }
			
		</tr>
	</thead>
	<tbody>
  {
    rows.map( elc =>
		<tr style={{
      marginTop:15
    
    }}>
 
   
      <td data-title={'Nome' }>{elc.fullname}</td>
      <td data-title={'CPF/CNPJ' }>{elc.tax_identifier}</td>
      <td data-title={'Referência Pedido' }>{elc.reference?elc.reference:" - "}</td>
      <td data-title={'Vencimento' }>{elc.due_date}</td>
      <td data-title={'Data de Pagamento' }>{elc.payment_date??" - "}</td>
      <td data-title={'Valor Total' }>{elc.amount}</td>
      <td data-title={"Valor Pago"}>
         <Typography>
          {
            elc.status["id"]!=7?elc.amount_paid:
           elc.amount_marked_paid
          }
        </Typography></td>
      <td data-title={'Status' }>
      <Button
          variant="contained"
          size="small"
          color={elc.status?ColorButtonTransaction(elc.status["id"]):"primary"}
       
          onClick={e => navigate("/cobrancas/show/"+elc.id)}>
          {elc.status?elc.status["name"]:null}
        </Button>
      </td>
        
		</tr>
    )
  }
	</tbody>
</table>
<Divider my={3} ></Divider>

  <br></br>
  <br></br>
  <div class="pagination">
    
    <a href="#" onClick={e=> onPageChange(page-1)}>&laquo;</a>
    <a href="#">Página {page+1}</a>
    <a href="#" onClick={e=> onPageChange(page+1)}>&raquo;</a>

  </div>
</section>

    </React.Fragment>

  );
}


export default DataGridImprovedCobList;
