import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import $  from 'jquery'
import 'jquery-mask-plugin/dist/jquery.mask.min'; 
import LogoIcon from "../../../assets/logo.webp";

import {getUrlImage} from "../../../utils/infoUtils"

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  DialogContent
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, fontFamily, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../estados-brasileiros'
import { useReactToPrint } from "react-to-print";

import { useNavigate } from "react-router-dom";
import { Preview, print } from 'react-html2pdf';

function RechargeView(
  {onClose, selectedValue, open, phone,provider,mobileProviders,rechargeValue,recharge}
) {

  $('#contacomdigito_cad_transferencia').mask("#0-0", {reverse: true});


  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS,businessAccount } = useAuth();
  let [loadAssociatedBankList, setLoadingAssociatedBankList] = useState(true);
  let [associatedBankList, setassociatedBankList] = useState([]);



  const handleClose = () => {
    onClose(selectedValue);
  };

  useEffect(() => {
    console.log(selectedValue)
    console.log(recharge)
    setIsPrinting(false);
    if(recharge){
    
      setResultRecharge(recharge);
    }

  }, [open,recharge]);
 

  let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');
  
  let [isPrinting, setIsPrinting] = useState(false);

  
  let [resultRecharge, setResultRecharge] = useState('');

  
  const componentRefTable = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRefTable.current,
    onBeforeGetContent : () => {
      return new Promise((resolve, reject) => {
        setIsPrinting(true);
        resolve();
      });
    },
    onAfterPrint : () => {
      return new Promise((resolve, reject) => {
        setIsPrinting(false);
        resolve();
      });
    }
  });

const handleSubmit = async (event) => {
  
  event.preventDefault();
  setSaving(true);
  setErrorMsg('')
   let response = await postApi("/rechages/cellphone",{
    provider_id:parseInt(provider),
    product_id:parseInt(rechargeValue),
    number:String(phone).replace(/\D/g, "")
   });
 
  response_run(response);

};


const response_run=(response)=>{
  if(response.success){
    alert("Recarga efetuada com sucesso.");
    setResultRecharge(response.recharge);
    
  setSaving(false);
  }else{

    if(response.provider_id){
      setErrorMsg(response.provider_id.toString())
    }else if(response.product_id){
      setErrorMsg(response.product_id.toString())
    }else if(response.number){
      setErrorMsg(response.number.toString())
    }
    else{
      setErrorMsg( response.error)
    }
    setSaving(false)
  }
}


  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"xs"}
      ref={componentRefTable}
    >
      <Preview  id={'modal-recharge'} >
      {isPrinting &&
        <div >
        <br></br>
      <center>
        <img src={LogoIcon} width={120}></img>
      </center>
      </div>
      
      }
    
    
      <DialogTitle id="simple-dialog-title" ><center>{resultRecharge?"Comprovante de Recarga":"Confirmação de Recarga"}</center></DialogTitle>
      
      {resultRecharge &&
     <center>  <i><small>Transação: {resultRecharge.id}</small></i>  </center>
      }

    
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

      <TableContainer component={Paper} sx={{ width:isPrinting?'600':'400px'}}>
      <Table  size="medium" aria-label="a dense table">
        <TableHead>

       
          <TableRow>
            <TableCell align="center">Operadora:</TableCell>
            <TableCell align="center">  {getUrlImage(
                 provider&& mobileProviders.find((x) => x.id==provider).name
            )} </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Telefone:</TableCell>
            <TableCell align="center">
              ({String(phone).replace(/\D/g, "").slice(0,2).toString()}) {String(phone).replace(/\D/g, "").slice(2,7).toString()}-{String(phone).replace(/\D/g, "").slice(7,11).toString()}
            
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell align="center">Valor:</TableCell>
            <TableCell align="center">
              {
                rechargeValue && 
                provider
                && mobileProviders.find((x) => x.id==provider).products.find( (p) => p.id===rechargeValue).name
              }
            
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center"><Typography sx={{
              color:"green"
            }}>CashBack:</Typography></TableCell>
            <TableCell align="center">
             
            <Typography sx={{
              color:"green"
            }}> {
              rechargeValue && 
              provider&& mobileProviders.find((x) => x.id==provider).products.find(p => p.id===rechargeValue).cashback["description"]
            }</Typography>
            </TableCell>
          </TableRow>


          {resultRecharge &&
          
          <React.Fragment>
            <TableRow>
            <TableCell align="center"><Typography >NSU da Recarga:</Typography></TableCell>
            <TableCell align="center">
             
            <Typography>{resultRecharge.nsu} </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><Typography >Código de Autorização:</Typography></TableCell>
            <TableCell align="center">
             
            <Typography>{resultRecharge.authorization_code} </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><Typography >Data de realização:</Typography></TableCell>
            <TableCell align="center">
             
            <Typography>
            {  new Date(resultRecharge.created_at).toLocaleDateString('pt-br')+
                     " "+
                     new Date(resultRecharge.created_at).toLocaleTimeString('pt-br')
                     }
            </Typography>
            </TableCell>
          </TableRow>

        
          </React.Fragment>
          
          
          }


        </TableHead>
   
      
      </Table>
    </TableContainer>
    {
      resultRecharge && 
      <React.Fragment>
        <br></br>
          <center>   
        {[2].includes(resultRecharge.status["id"])
          &&
          <Typography sx={{color:'green'}}>A recarga efetuada com sucesso</Typography>
        }
        {[0,1].includes(resultRecharge.status["id"])
          &&
          <Typography sx={{color:'blue'}}>A recarga está {resultRecharge.status["name"]}</Typography>
        }
        {[3,4,5].includes(resultRecharge.status["id"])
          &&
          <Typography sx={{color:'red'}}>A recarga foi {resultRecharge.status["name"]}</Typography>
        }
      </center>
      </React.Fragment>
    }
    
    {
          resultRecharge && 
                provider &&
              <TableRow>
            <TableCell align="center">ID Transação:</TableCell>
            <TableCell align="center"> <small>{resultRecharge.id}</small></TableCell>
          </TableRow>
          }


            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
              <br></br>
              <Grid container justifyContent="center" >

           
              {
                !resultRecharge &&
                !isPrinting &&

                <Grid item>
                <center>   
                  <Button variant="contained" disabled={saving} color="primary" mt={3} onClick={handleSubmit} className="hiddenwhenprint">
                    Realizar Recarga
                 </Button>
                </center>
                </Grid>
              }

            {
              resultRecharge &&
              !isPrinting &&
              <React.Fragment>
                <Grid item>
              <center>   
                <Button variant="contained"  color="boot_primary" mt={3} onClick={e=> handlePrint()} className="hiddenwhenprint">
                  Imprimir
                </Button>
              </center>
              </Grid>

              <Grid item>
              <center>   
                <Button variant="contained"  color="boot_warning" mt={3} onClick={e=> 
                  
                  {
                    new Promise((resolve, reject) => {
                      setIsPrinting(true);
                      resolve();
                    }).then(

                      ()=>{
                        print(
                          'comprovante-recarga-'+mobileProviders.find((x) => x.id==provider).name+"-num-"+phone,
                        'modal-recharge',{useCors:true});

                      }


                    ).then(
                     ()=> {setIsPrinting(false);}
                    )
               
               
                  
                }} className="hiddenwhenprint">
                  PDF
                </Button>
              </center>
              </Grid>
              </React.Fragment>
            }


          
            </Grid>

            {
              
              !isPrinting &&
              <Button variant="contained"  color="secondary" mt={3} onClick={handleClose} className="hiddenwhenprint" >
                  Fechar
                  </Button>
            }
              
              
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                


        </Preview>

    </Dialog>
  )
}


export default RechargeView;
