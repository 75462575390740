import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

 if(user){
    localStorage.setItem("@CacheBank_", {email: user.email });
  }

  if (isInitialized && !isAuthenticated) {
    return  window.location.href="/sign-in"; 
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
