import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
} from "react-feather";

import { boleto } from 'boleto-brasileiro-validator';


import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  Link,
  ListSubheader,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography as MuiTypography,
} from "@mui/material";
import { fontSize, spacing } from "@mui/system";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import PayPaymentAccountModuloTitulo from "./modulo-boleto";
import {
  boletoArrecadacao, // valida qualquer tipo de boleto de arrecadação
  boletoArrecadacaoCodigoBarras, // valida representação númerica do código de barras
  boletoArrecadacaoLinhaDigitavel, // valida linha digitável do boleto,
   boletoBancario, // valida qualquer tipo de boleto bancário
    boletoBancarioCodigoBarras, // valida representação númerica do código de barras
    boletoBancarioLinhaDigitavel
} from 'boleto-brasileiro-validator';
import PayPaymentAccountModuloTituloComprovante from "./modulo-boleto-comprovante";


const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);



function PayPaymentAccount() {

  let { getApi,postApi } = useAuth();

const [loading, setLoading] = useState(false);

const Typography = styled(MuiTypography)(spacing);


const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

const [barCode, setBarCode] = useState('');
const [barCodeType, setBarCodeType] = useState(null);
const [errorMsg, setErrorMsg] = useState(null);

const { id } = useParams();

 
useEffect( () => {
  const initializeBoleto = async () => {
    setBarCodeType(null)
    let barcodeTypeTMP=null;
  if(barCode.length>=44){
    barcodeTypeTMP=RetornarLinhaDigitavel(barCode,barcodeTypeTMP);
    QueryBarCode(barcodeTypeTMP);
  }
  setBarCode(String(barCode).replace(/\D/g, "").replace(/\./g, '').replace(/ /g, ''))
};
if(!id){
  initializeBoleto();
}else{
  LoadPayment();
}
}, [barCode,id]);


const RetornarLinhaDigitavel= (codigoBarra, barcodeTypeTMP) =>{
  setBarCodeType(null)
  
  barcodeTypeTMP=null;
  console.log(boletoBancarioLinhaDigitavel(codigoBarra));
  if(boletoBancarioCodigoBarras(codigoBarra, true)===true){
    console.log(codigoBarra)
    setBarCodeType(1);
    barcodeTypeTMP=1;
    return 1;
  }else if(boletoBancarioLinhaDigitavel(codigoBarra, true) === true){
    setBarCodeType(2);
    barcodeTypeTMP=2;
    return 2;
  }if(boletoArrecadacaoCodigoBarras(codigoBarra) === true){
    setBarCodeType(1);
    barcodeTypeTMP=1;
    
    return 1;
  }else if(boletoArrecadacaoLinhaDigitavel(codigoBarra) === true){
    setBarCodeType(2);
    barcodeTypeTMP=2;
    return 2;
  }else{
    setBarCodeType(-1);
    barcodeTypeTMP=-1;
     return -1;
  }; 
}


const LoadPayment = async()=>{

  setLoading(true);
  let response = await getApi("/payment/boleto/"+id, {});

  setBoletoData(response.payment);
  
  setLoading(false);
}


const QueryBarCode = async(barcodeTypeTMP=null)=>{
  setErrorMsg(null);
  if(barCodeType==-1){
    return ;
  }

  setLoading(true);
  let response = await postApi("/payment/boleto", {
    payment_code:barCode,
    type_payment_code:barcodeTypeTMP?barcodeTypeTMP:barCodeType
  });

  setErrorMsg(response.error?response.error:null);
  setBoletoData(response.payment);
  
  setLoading(false);
}

const [boletoData, setBoletoData] = React.useState(null);



  return (
    <React.Fragment>
      <Helmet title={"Cobrança"} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
          Pagamento de contas
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/cobrancas">
            Pagamento de contas
            </Link>
          </Breadcrumbs>
        </Grid>
 
      
      </Grid>


      <Divider my={3} />
    
    
        <Grid sx={{justifyContent:'center', marginTop:0}} container >
             
        {!boletoData && 
        !id &&
        
          <Grid item md={6}>
          <center><Typography variant="subtitle2">Realize o pagamento do seu boleto</Typography></center>

          <TextField 
          value={barCode}
          disabled={loading}
          onChange={e=> setBarCode(String(e.target.value).replace(/\D/g, ""))}
          label="Informe ou escaneie o código de barras" variant="outlined" fullWidth={true} />
          
          <center><Typography variant="caption">Escaneie ou digite o código de barras.</Typography></center>

          <center>{barCodeType===-1 && "Código de barras inválido"}</center>
          <center><Typography sx={{color: "red"}}>{errorMsg && errorMsg}</Typography></center>
          {loading && 
                  <Grid item>
                  <Box sx={{ display: 'flex' }} justifyContent="center">
                    <CircularProgress />
                  </Box>
                </Grid>
          }
          </Grid>
           }
            {loading && 
            id &&
                  <Grid item>
                  <Box sx={{ display: 'flex' }} justifyContent="center">
                    <CircularProgress />
                  </Box>
                </Grid>
          }

            {boletoData && 
              [1,2].includes(boletoData.status.id) &&
              !id &&
            <Grid item md={6}>
              <PayPaymentAccountModuloTitulo boletoData={boletoData} setBoletoData={setBoletoData}/>
            </Grid>
            }

          {boletoData && 
              [4,3].includes(boletoData.status.id) &&
            <Grid item md={6}>
              <PayPaymentAccountModuloTituloComprovante boletoData={boletoData} setBoletoData={setBoletoData}/>
            </Grid>
            }
            



      
      </Grid>
       

    </React.Fragment>
  );
}

export default PayPaymentAccount;
