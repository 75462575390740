import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import AddNewPayeeAccount from "../add/index"

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi ,deleteApi} = useAuth();
const [listPayeersAccounts, setListPayeersAccounts] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = React.useState(0);
const [limitPage, setLimitPage] = React.useState(5);
const [totalRecords, setTotalRecords] = React.useState(0);

const [openEditClient, setOpenEditClient] = React.useState(false);
const [selectedEditValue, setSelectedEditValue] = React.useState([]);
const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const columns = [
  { field: "id", headerName: "ID", width: 150,  hide: true },
  {
    field: "business_name",
    headerName: "Nome",
    width: 200,
    editable: false,
  },
  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    width: 200,
    editable: false,
  },
  {
    field: "agency",
    headerName: "Agência",
    width: 100,
    editable: false,
    sortable: false,
  },
  {
    field: "account_number",
    headerName: "Numero da Conta",
    width: 200,
    editable: false,
    sortable: false,
  },
  {
    field: "account_type_name",
    headerName: "Tipo",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
  field: "ltbank",
  headerName: "Instituição",
  sortable: false,
  width: 300,
  renderCell: (params: GridRenderCellParams<Date>) => (
    <React.Fragment>
     {params.row.bank["name"]}
    </React.Fragment>
      
  )},
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 300,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={"primaryboot"}
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          disabled={loadingList}>
          Selecione
        </Button>

        <Button
          variant="contained"
          size="small"
          color="error"
          style={{ marginLeft: 16 }}
          onClick={e=>deletePayee(params.row.id)}
          tabIndex={params.hasFocus ? 0 : -1} 
          disabled={loadingList}>Excluir
        </Button>
      </React.Fragment>
    
        
    ),
  },
];



const [query_type, setQueryType] = React.useState("fullname");
const [query, setQuery] = React.useState(null);
const { balance_available } = useAuth();

useEffect(() => {

  LoadMyPayeeBank();
  setSelectionModel([]);
},[]);

const LoadMyPayeeBank = async (page_arg=null, limit_arg=null) => {
    
  setListPayeersAccounts([]);
  setLoadingList(true);
  let response = await getApi("/payee_account/list",{
      limit:limit_arg!=null?limit_arg:limitPage,
      page:page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page),
      query_type:query_type,
      queryString:query
  });
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListPayeersAccounts(response.lista.map(x=> x.payeeAccount));
  setLoadingList(false);

};


const deletePayee = async (id) => {
  if (!window.confirm("Você realmente deseja excluir esta conta?")) {
    return ;
  }
  

  setLoadingList(true);
  let response = await deleteApi("/payee_account/"+id,{} );
  if(response.success==true){
    alert("Conta bancária excluida com sucesso");
    
  setLoadingList(true);
    LoadMyPayeeBank();
  }else{
    
  setLoadingList(false);
  }

  
};
const [selectionModel, setSelectionModel] = useState([]);
const [amountWithdrawView, setAmountWithdrawView] = useState(0.00);
const [amountWithdraw, setAmountWithdraw] = useState(0.00);
const [payeerAccount, setPayeerAccount] = useState({});
const [transferTypeId, setTransferTypeId] = useState(2);


const setAmountWithdrawViewTMP= (val) =>{
  val = String(val).replace(/\D/g, "");

  setAmountWithdrawView(val/100);
  setAmountWithdraw(val/100);
}

const setRow=(row)=>{
  setSelectionModel(row);
  setPayeerAccount(listPayeersAccounts.length>0 && row[0]?listPayeersAccounts.find(x=> x.id==row[0]):[]);
}


let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');

  const checkValues=()=>{
    if(!payeerAccount.id){
      alert("Você precisa selecionar a conta bancária");
    }else if(!amountWithdraw){
      alert("Você precisa preencher o valor a transferir");
    }else if(amountWithdraw<5){
      alert("O valor a transferir precisa ser no mínimo R$ 5,00 ");
    }else if(!amountWithdraw){
      alert("Você não pode transferir esse valor.");
    }
    
  else{
      return true;
    }
  }

const handleTransfer = async (event) => {
  
  event.preventDefault();
  setSaving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSaving(false);
    setErrorMsg('')
    return false;
  }

   let response = await postApi("/payee_account/withdraw",{
    payee_account_id:payeerAccount.id,
    amount:amountWithdraw,
    transfer_type_id:transferTypeId


   });
 
  response_run(response);

};


const response_run=(response)=>{
  if(response.success){
    alert("Transferência efetuada com sucesso.");
    navigate("/transferencias");
  }else{

    if(response.payee_account_id){
      setErrorMsg(response.payee_account_id.toString())
    }else if(response.amount){
      setErrorMsg(response.amount.toString())
    }else if(response.transfer_type_id){
      setErrorMsg(response.transfer_type_id.toString())
    }else if(response.schedule_at){
      setErrorMsg(response.schedule_at.toString())
    }else{
      setErrorMsg( response.error)
    }
    setSaving(false)
  }
}


  return (
    <Card mb={0}>
      <CardContent pb={1}>
  
       
      </CardContent>
                 
      <Paper>
        <div style={{ height: (limitPage==3?270:270+(limitPage*15)), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[3, 5, 10,15,20,50,100]}
            rows={listPayeersAccounts}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyPayeeBank(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyPayeeBank(e, null)  } }
            loading={loadingList}
            disableMultipleSelection
            onSelectionModelChange={(selection) => {
             
              setRow(selection);
            }}
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}

            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Não existe contas bancárias cadastradas
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
            
          />
    
        </div>
              
      {
        payeerAccount.id &&
        !loadingList &&
        <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

            <Grid container spacing={2}>
              
                <Grid item md={12}>
                <label for="amountWithdraw">Valor a transferir </label>
               
                </Grid>
                <Grid item md={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Tipo de transferência</FormLabel>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={transferTypeId}
                    onChange={e=> setTransferTypeId(e.target.value)}
                  >
                    <FormControlLabel value={2} control={<Radio />} label="Pix" />
                    <FormControlLabel value={1} control={<Radio />} label="TED" />
                  </RadioGroup>
                </FormControl>
                </Grid>
                <Grid item>
                <TextField   required
                  name="amountWithdraw"
                  type="text"
                  id="amountWithdraw"
                  placeholder="R$"
                  value={parseFloat(amountWithdrawView).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                onChange={x => setAmountWithdrawViewTMP(x.target.value)}
                InputProps={{
                  endAdornment:      
                  <Button size="large" color="boot_success" onClick={handleTransfer} variant={"contained"} disabled={amountWithdraw>balance_available}   disabled={saving}>
                Transferir
              </Button>,
              color: amountWithdraw>balance_available?'error':'success'}}
                />
            

                </Grid>
           
                <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
                <Grid item md={12} mb={1}>
               
                <span>Disponivel: {balance_available!=null?parseFloat(balance_available).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):"não disponivel"}</span>
                <p></p>
                <Typography variant="body" sx={{fontWeight:"bolder"}} gutterBottom display="inline">
                  Instituição:  
                </Typography>
                <Typography variant="body" gutterBottom display="inline">
                  {" "+payeerAccount.bank["code"]+" - "+payeerAccount.bank["name"]}
                </Typography>
                </Grid>

                <Grid item md={12} mb={1}>
                <Typography variant="body" sx={{fontWeight:"bolder"}} gutterBottom display="inline">
                  Agência/Conta:  
                </Typography>
                <Typography variant="body" gutterBottom display="inline">
                  {" "+payeerAccount.agency+" - "+payeerAccount.account_number}
                </Typography>
                </Grid>

                <Grid item md={12} mb={1}>
                <Typography variant="body" sx={{fontWeight:"bolder"}} gutterBottom display="inline">
                  Titular:  
                </Typography>
                <Typography variant="body" gutterBottom display="inline">
                  {" "+payeerAccount.business_name}
                </Typography>
                </Grid>


              </Grid>
             
                
                
          
                 
            </CardContent>
        </Card>
        </Grid>
      </Grid>
      }




      </Paper>

      
    </Card>

    
  );
}

  function PayeeAccountList() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);
    };





  return (
    <React.Fragment>
      <Helmet title="Contas Bancárias" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Contas Bancárias
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/transferencias">
          Transferência
        </Link>
        <Typography>Contas Bancárias</Typography>
      </Breadcrumbs>
      </Grid>
      <Grid item>
          <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              <AddIcon />
              Cadastrar nova conta
            </Button>
          </div>
        </Grid>
      
        </Grid>


  
      <DataGridClientes />






      <AddNewPayeeAccount
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
          />

       
       
    </React.Fragment>
  );
}

export default PayeeAccountList;
