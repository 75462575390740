import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import TableCarnes from "./table"

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  CardActions,
  TableHead,
  TableContainer,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  FormControlLabel,
  Switch
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";

import { useNavigate } from "react-router-dom";
import { Table } from "react-feather";


function AddNewClient(props) {
  let { getApi,postApi,deleteApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();

  const { onClose, selectedValue, open } = props;


  const [search_Status, setSearchStatus] = useState([0]);


const [costumer, setCostumer] = useState({});
const [invoices, setInvoices] = useState([]);

const [loading, setLoading] = useState(true);
const [blockAction, setBlockAction] = useState(false);
const [cancelling, setCancelling] = useState(false);

let yesterdayDate=Date.now() + -1*24*3600*1000;
const [query_filterDateEnd, setQuery_filterDateEnd] = useState(new Date(yesterdayDate).toISOString().slice(0,10));
let [includeExpiredInvoices, setIncludeExpiredInvoices] = useState(true);

let [includeAllPendingInvoices, setIncludeAllPendingInvoices] = useState(false);


  useEffect(() => {
    if(selectedValue.id){

      LoadInvoices();
      setCostumer(selectedValue)
    }
  },[selectedValue]);

  const handleClose = () => {
    onClose(selectedValue);

  };

  const setAllPendingInvoices=()=>{
    let fl=includeAllPendingInvoices;

    setIncludeAllPendingInvoices(!fl)
    includeAllPendingInvoices=!fl;

    LoadInvoices();
  }


  const LoadInvoices = async (page_arg=null, limit_arg=null) => {
    
    let tempSearchStatus=search_Status;
    if(includeExpiredInvoices){
      tempSearchStatus=[0,10]
    }


    setLoading(true);
    let response = await postApi("/invoice/list", {
      limit:200,
      minimal_data:false,
      queryString: {
        costumer:{id:selectedValue.id},
        invoice:{
          status:tempSearchStatus,
          filterdate:3,
          filterdatevalue:{
            end:includeAllPendingInvoices?null:query_filterDateEnd
          }

        }
    }
    });
    
    //setCostumer(response.carne);
    setInvoices(response.lista.map(x=> x.invoice));
    setLoading(false);
  
  };


  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle id="simple-dialog-title" ><center>Informações do Cliente - #{costumer?costumer.fullname:null}</center></DialogTitle>
       
            <Grid container justifyContent="center" >
            <Grid item>
            <Typography color="#df4759" variant="h7" ></Typography>
            {loading &&
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>}
            </Grid>
          </Grid> 


      {loading==false && 
      <React.Fragment>
      <Grid container spacing={0} marginLeft={2} marginRight={2}>
        <Grid item xs={4} >
          <Card >
            <CardContent>
              <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.secondary" gutterBottom>
                Dados de cobranças
              </Typography>

              
              <Typography variant="body2">
                Parcelas Totais:  {costumer.invoices_total}
              </Typography>
              <Typography variant="body2">
                Parcelas Pagas:  {costumer.paid}
              </Typography>
              <Typography variant="body2">
                Parcelas Pendentes:  {costumer.pending}
              </Typography>
              <Typography variant="body2">
                Parcelas Vencidas:  {costumer.overdue}
              </Typography>
              <Typography variant="body2">
                Parcelas Expiradas:  {costumer.expired}
              </Typography>
            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={4} >
        <Card >
            <CardContent>
              <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.secondary" gutterBottom>
                Dados do Cliente
              </Typography>

              <Typography variant="body2">
                ID:  {costumer.id}
              </Typography>
              <Typography variant="body2">
                Nome:  {costumer.fullname}
              </Typography>
              <Typography variant="body2">
                CPF/CNPJ:  {costumer.tax_identifier}
              </Typography>
              <Typography variant="body2">
                Referência:  {costumer.reference}
              </Typography>
              <Typography variant="body2">
                E-mail:  {costumer.email}
              </Typography>
              <Typography variant="body2">
                Telefone: {costumer.phone}
              </Typography>
             
            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={4}>
          <Card >
            <CardContent>
              <Typography sx={{ fontSize: 18 }} variant="subtitle1" color="text.secondary" gutterBottom>
                Status dos pagamentos
              </Typography>

              <FormControlLabel
          value={true}
          checked={includeExpiredInvoices}
          onChange={e=> {
            setIncludeExpiredInvoices(!includeExpiredInvoices);
            LoadInvoices();
          }}
          control={<Switch color="primary" />}
          label="Incluir Faturas expiradas"
          labelPlacement="Incluir Faturas expiradas"
        />
         <FormControlLabel
         checked={includeAllPendingInvoices==true}
         
         onChange={e=> setAllPendingInvoices()}
          defaultValue={includeAllPendingInvoices}
          control={<Switch color="primary" />}
          label="Incluir todas faturas pendentes"
        />
             
                <Grid container justifyContent="center" >
            <Grid item>
            <Typography color="#df4759" variant="h7" ></Typography>
            {cancelling &&
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>}
            </Grid>
          </Grid> 

            </CardContent>
          </Card>

        </Grid>
        
        </Grid>

        

        <Grid container spacing={0} marginLeft={2} marginRight={2}>
          <Grid item xs={12} >
            <TableCarnes blockAction={blockAction} setBlockAction={setBlockAction} invoices={invoices}/>
          </Grid>
          
          <Grid item xs={12} >

          <Box textAlign='right' marginTop={7} marginBottom={5} marginRight={3}>
              <Button variant='contained' color="secondary" onClick={handleClose}>
               Fechar
              </Button>
          </Box>
          </Grid>
       
              
        </Grid>
        </React.Fragment>}
                




    </Dialog>
  );
}


export default AddNewClient;
