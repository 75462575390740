import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import UpdateDueDate from "../update-due/index"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import UpdateStatusCobMarkedPaid from "../update-marked-as-paid"

import EditClient from "../edit/index"
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ListCustomFieldComponent from "../../CustomField/ListCustomFieldComponent";
const Card = styled(MuiCard)(spacing);

const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);



function DataGridPage({state}) {
 

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();

  const { id } = useParams();


  const [cobranca, setCobranca] = useState({});
  const [loading, setLoading] = useState(true);

  
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

  const [blockSent, setBlockSent] = useState(false);

  const [loadingArchiving, setLoadingArchiving] = useState(false);

  

  useEffect(() => {
    LoadCob();
  },[]);

  const LoadCob = async (loadBox=true) => {
    
    setLoading(loadBox?true:false);
    let response = await getApi("/invoice/show/"+id,{});
    
    setCobranca(response.invoice);
    setLoading(false);
  
  };

  const copyTextLink=()=>{
    navigator.clipboard.writeText(cobranca.link)
    alert("Link copiado com sucesso!");
  }

  const ArchiveAct = async (archiveSt) => {
    if (archiveSt==true && !window.confirm("Esta cobrança estará classificada como arquivada.\nVocê deseja continuar?")) {
      return ;
    }
    
    setLoadingArchiving(true);
    let response = await postApi("/invoice/archive/"+id+"/"+archiveSt,{});
    if(response.error){
      alert(response.error.toString());
    }else{
      let cob=cobranca;
      console.log(archiveSt);
      cob["archived"]=archiveSt;

      setCobranca(cob);
      alert("Cobrança "+(archiveSt?"arquivada":"desarquivada"))
      LoadCob(false)
      
    }
    setLoadingArchiving(false)
  };

  const ChangeStatus = async (status) => {
    if (!window.confirm("Você realmente deseja fazer isso?")) {
      return ;
    }
    setLoadingChangeStatus(true);
    let response = await postApi("/invoice/marcar/"+status+"/"+id,{});
    if(response.error){
      alert(response.error.toString());
    }else{
      let cob=cobranca;
      if(status=="pago"){
        cob["status"]["id"]=7;
        cob["status"]["name"]="Marcado como Pago";
        cob["status"]["extended_name"]="Marcado como Pago";
     
      }else  if(status=="cancelado"){
        cob["status"]["id"]=3;
        cob["status"]["name"]="Cancelada";
        cob["status"]["extended_name"]="Transação Cancelada";
       

      }
      setCobranca(cob);
      LoadCob(false)
    }
    setLoadingChangeStatus(false)
  };


  const ColorButtonTransaction =(status)=>{
    let color="primary";
    if(status==0){
      color="boot_warning"
    }else if(status==1){
      color="boot_success"
    }else if(status==2){
      color="boot_info"
    }else if(status==3){
      color="boot_secondary"
    }else if(status==4){
      color="boot_dark"
    }else if(status==5){
      color="boot_danger"
    }else if(status==6){
      color="boot_dark"
    }else if(status==7){
      color="boot_success"
    }else if(status==8){
      color="boot_success"
    }else if(status==9){
      color="boot_success"
    }else if(status==10){
      color="boot_secondary"
    }
  
    return color;
  }

  
const [openUpdateDue, setopenUpdateDue] = useState(false);
const [openUpdateStatusMarkedPaid, setOpenUpdateStatusMarkedPaid] = useState(false);
const handleCloseInvoiceUpdateDue = (value) => {
  setopenUpdateDue(false);
  setCobranca(value)
};

const handleCloseInvoiceUpdateMarkedPaid = (value) => {
  setOpenUpdateStatusMarkedPaid(false);
  setCobranca(value)
};


const sentNotificationEmail=async ()=>{
  if (!window.confirm("Você deseja enviar um lembrete via e-mail?")) {
    return ;
  }

  setBlockSent(true);
  let response = await postApi("/notification/email/invoices/pending",{
    invoices:[
      id
    ]
  });
  if(response.success){
    alert("Lembrete via e-mail enviado com sucesso.");
  }
  
  setBlockSent(false);
}


const sentNotificationSMS=async ()=>{
  if (!window.confirm("Você deseja enviar um lembrete via sms? Taxa de envio pode ser aplicada.")) {
    return ;
  }

  setBlockSent(true);
  let response = await postApi("/notification/sms/invoices/pending",{
    invoices:[
      id
    ]
  });
  if(response.success){
    alert("Lembrete via SMS enviado com sucesso.");
  }
  
  setBlockSent(false);
}


  return (
    <React.Fragment>
      <Helmet title={"Cobrança"} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
          Visualizar cobrança
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/cobrancas">
              Cobranças
            </Link>
            <Typography>{id}</Typography>
          </Breadcrumbs>
        </Grid>
 
      
      </Grid>


      <Divider my={6} />
    
      {loading && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
        <Grid sx={{justifyContent:'center', marginTop:5}} container >
             
       
      {!loading &&
      
      
   
          <TableContainer component={Paper} sx={{ width:'600px'}}>
           
            <Table  size="medium" aria-label="a dense table">
              <TableBody>
             
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                     <strong>Status</strong> 
                    </TableCell>
                    <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <Button
                        variant="contained"
                        size="small"
                        color={cobranca.status?ColorButtonTransaction(cobranca.status["id"]):"primary"}>
                        {cobranca.status?cobranca.status["name"]:null}
                      </Button>
                      {cobranca.archived&&" (Arquivada)"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                    <strong>Data do pedido</strong> 
                    </TableCell>
                    <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                    {new Date(cobranca.created_at).toLocaleDateString('pt-br') }
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                    <strong> Código da transação</strong> 
                    </TableCell>
                    <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                    {id}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                    <strong> Referência</strong> 
                    </TableCell>
                    <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                    {cobranca.reference}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                    <strong>Valor Total:</strong> 
                    </TableCell>
                    <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                    {parseFloat(cobranca.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </TableCell>
                  </TableRow>

                  {cobranca.amount_paid>0 &&
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>Valor Pago:</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                      {parseFloat(cobranca.amount_paid).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                      </TableCell>
                    </TableRow>
                  }

                  {cobranca.amount_marked_paid>0 && cobranca.status["id"]==7 &&
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>Valor Pago:</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                      {parseFloat(cobranca.amount_marked_paid).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} <Link onClick={e=>setOpenUpdateStatusMarkedPaid(true)} > (Atualizar valor)</Link>
                      </TableCell>
                    </TableRow>
                  }

                  {cobranca.fees>0 &&
                  <React.Fragment>
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>Tarifa:</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                      {parseFloat(cobranca.fees).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                      </TableCell>
                    </TableRow>
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>Valor Liquido:</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                      {parseFloat(cobranca.amount_paid-cobranca.fees).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                  }
               <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                  <strong> Forma de Pagamento:</strong> 
                  </TableCell>
                  <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                    {cobranca.charges_types &&
                      cobranca.charges_types.map(x=> x.charge_type_name).toString()
                    }
                  </TableCell>
                </TableRow>   

              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                <strong>Vencimento</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                {new Date(cobranca.due_date).toLocaleDateString('pt-br') } 
                
                {cobranca.status["id"]==0 &&  <Link onClick={e=>setopenUpdateDue(true)} > (Atualizar vencimento)</Link>
                }
                </TableCell>
              </TableRow> 

              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                <strong>Link de Pagamento</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                  <Link
                  target="_blank"
                  href={cobranca.link}>
                  <Button
                          variant="outlined"
                          size="small"
                          color={"boot_info"}>
                        Abrir
                  </Button>
                  </Link>

                  <Button
                        variant="outlined"
                        size="small"
                        color={"boot_secondary"}
                        onClick={copyTextLink}>
                       Copiar
                  </Button>

               
                  
                  {cobranca.boleto &&
                    <Link
                      target="_blank"
                      href={cobranca.boleto["url_slip_pdf"]}>
                      <Button
                          variant="outlined"
                          size="small"
                          color={"boot_warning"}>
                        PDF
                      </Button>
                    </Link>
                  
                  }

<Link
                  target="_blank"
                  href={cobranca.whatsapp_link}>
                  <Button
                          variant="outlined"
                          size="small"
                          color={'boot_success'}>
                          <WhatsAppIcon />
                  </Button>
                  </Link>
                  
                </TableCell>
              </TableRow> 

              {cobranca.status["id"]==0 &&
                <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell component="th" scope="row" sx={{fontSize:16, fontFamily:FontFamily}}>
                <strong>Reenviar Lembrete</strong> 
                </TableCell>
                <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                  <Button
                          variant="outlined"
                          size="small"
                          color={"boot_secondary"}
                          disabled={blockSent}
                          onClick={sentNotificationSMS}>
                        SMS
                  </Button>
                  <Button
                        variant="outlined"
                        size="small"
                        color={"boot_secondary"}
                        onClick={sentNotificationEmail}
                        disabled={blockSent}>
                       E-mail
                  </Button>
                  {blockSent && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
                </TableCell>
              </TableRow> 
              }
              
            


            
              </TableBody>
              
            </Table>
            
      <Divider my={2} />
            <Box textAlign='center' >
                 {!loadingChangeStatus && 
                <React.Fragment>
                   <Button
                        variant="contained"
                        size="medium"
                        color={"boot_danger"}
                        onClick={e=> ChangeStatus("cancelado")}
                        disabled={![0,7,10].includes(cobranca.status["id"])}>
                       Cancelar
                  </Button>
                  <Button
                        variant="contained"
                        size="medium"
                        color={"boot_success"}
                        onClick={e=> setOpenUpdateStatusMarkedPaid(true)}
                        disabled={![0,3,10].includes(cobranca.status["id"])}>
                       Aprovar
                  </Button>
                </React.Fragment>
                 
                 
                 }



       
                     

          

                  {loadingChangeStatus && 
                  <Grid item>
                    <Box sx={{ display: 'flex' }} justifyContent="center">
                      <CircularProgress />
                    </Box>
                  </Grid>
                  }

               
              </Box>
            
             
                <Divider my={2} />
                <Tooltip title="Arquive as cobranças antigas que não precisam ser exibidas.">
                <Box textAlign='center' >
              
                    <Button
                        variant="contained"
                        size="medium"
                        color={"boot_light"}
                        onClick={e=> ArchiveAct(!cobranca["archived"])}
                        disabled={loadingArchiving || ![3,7,10].includes(cobranca.status["id"])}>
                        {cobranca["archived"]?"Desarquivar":"Arquivar"}
                    </Button>
                  
                  </Box>
                </Tooltip>

                
                {loadingArchiving && 
                  <Grid item>
                    <Box sx={{ display: 'flex' }} justifyContent="center">
                      <CircularProgress />
                    </Box>
                  </Grid>
                  }
            
              
      <Divider my={6} />
      <Typography variant="h5" gutterBottom display="inline">
            Dados do cliente
          </Typography>
              <Table  size="small" aria-label="a dense table">
                <TableBody>
              
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>Nome do cliente</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <Link
                        href={"/meus-clientes/visualizar/"+cobranca.costumer["id"]}>
                        {cobranca.costumer["fullname"]}
                      </Link>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>CPF/CNPJ</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                       {cobranca.costumer["tax_identifier"]}
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>E-mail</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                       {cobranca.costumer["email"]}
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                      <strong>Telefone</strong> 
                      </TableCell>
                      <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                       {cobranca.costumer["phone"]}
                      </TableCell>
                    </TableRow>

                </TableBody>
                
              </Table>
              



              <Divider my={6} />
      <Typography variant="h5" gutterBottom display="inline">
            Itens do Pedido
          </Typography>
          <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Descrição</TableCell>
              <TableCell align="center">Qtd</TableCell>
              <TableCell align="center">Valor unitário</TableCell>
            </TableRow>
          </TableHead>
          {  cobranca.itens.map(el => (
            <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    {el.description}
                  </TableCell>
                  <TableCell align="center">{el.qtd}</TableCell>
                  <TableCell align="center">
                  {parseFloat(el.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                  </TableCell>
            </TableRow>
          ))}
          
        </Table>




        <Divider my={6} />
      <Typography variant="h5" gutterBottom display="inline">
           Historico da transação
          </Typography>
          <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center">Descrição</TableCell>
            </TableRow>
          </TableHead>
          {  cobranca.historic.map(el => (
            <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    {  new Date(el.created_at).toLocaleDateString('pt-br')+
                     " "+
                     new Date(el.created_at).toLocaleTimeString('pt-br')
                     }
                  </TableCell>
                  <TableCell align="center">
                  {el.description}
                  </TableCell>
            </TableRow>
          ))}
          
        </Table>



        </TableContainer>
      }



      
      </Grid>
       
                 
<UpdateDueDate
            invoice={cobranca}
            open={openUpdateDue}
            onClose={handleCloseInvoiceUpdateDue}
          />

          <UpdateStatusCobMarkedPaid
            invoice={cobranca}
            open={openUpdateStatusMarkedPaid}
            onClose={handleCloseInvoiceUpdateMarkedPaid}
          />
    </React.Fragment>
  );
}

export default DataGridPage;
