import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Button,
  CircularProgress,
  TextField as MuiTextField,
  FormControl,
  Select as MUISelect,
  MenuItem,
  InputLabel,
  LinearProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import LogoMarkComponent from "./logomark/index"
import useAuth from "../../../hooks/useAuth";
import TerminateAccountBS from "./terminate-account";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


const TextField = styled(MuiTextField)(spacing);

const Select = styled(MUISelect)(spacing);

function Default() {
  const { t } = useTranslation();

  let { getApi,postApi,user,businessAccount } = useAuth();
  let [list_businessActivity, setListBusinessActivity] = useState([]);

  let [list_businessMonthlyBilling, setListBusinessMonthlyBilling] = useState([]);
  let [userBusinessAccountInfo, setUserBusinessAccountInfo] = useState([]);
  let [openTerminateAccount, setOpenTerminateAccount] = React.useState(false);

  let [terminateAccountM, setTerminateAccountM] = useState([]);



  let [bs_Identifier, setIdentifierBs] = useState(null);
  let [bs_company_name, setBScompany_name] = useState(null);
  let [bs_fantasy_name, setBSfantasy_name] = useState(null);
  let [bs_Type, setBStype] = useState(null);
  let [business_activity_id, setBusiness_activity_id] = useState(null);
  let [business_monthly_billing_id, setBusiness_monthly_billing_id] = useState(null);
  let [revertTerminating, setRevertTerminating] = React.useState(false);

  
  let [business_contacts_site, setBusiness_contacts_site] = useState(null);
  let [business_contacts_email, setBusiness_contacts_email] = useState(null);
  let [business_contacts_telefone, setBusiness_contacts_telefone] = useState(null);

  let [saving, setSaving] = useState(false);

  let [errormsg, setErrorMsg] = useState('');

  const loadRequestTerminateStatus=async ()=>{
  
    let response = await getApi("/request/cancel_account");
    if(response.result){
      setTerminateAccountM(response.result);
    }
  
  }

  
useEffect(() => {
  if(businessAccount.id){
    setIdentifierBs(businessAccount.identifier);
    setBScompany_name(businessAccount.company_name);
    setBSfantasy_name(businessAccount.fantasy_name);
    setBStype(businessAccount.type);
    loadRequestTerminateStatus();

    let contacts=businessAccount.contacts;

     setBusiness_contacts_site(contacts.find(x=> x.type==5)?contacts.find(x=> x.type==5).value:null);
     setBusiness_contacts_email(contacts.find(x=> x.type==1)?contacts.find(x=> x.type==1).value:null);
     setBusiness_contacts_telefone(contacts.find(x=> x.type==2)?contacts.find(x=> x.type==2).value:null);


    loadListBusinessAcitivty();
  loadListBusinessBusinessMonthly();
  
  setBusiness_activity_id(businessAccount.business_activity_id);
  setBusiness_monthly_billing_id(businessAccount.business_monthly_billing_id);
  }

},[businessAccount]);


const loadListBusinessBusinessMonthly=async ()=>{
  
  let response = await getApi("/list_business_monthly_billing");
  setListBusinessMonthlyBilling(response.lista);
}

  const loadListBusinessAcitivty=async ()=>{
  
    let response = await getApi("/list_business_activity");
    setListBusinessActivity(response.lista);
  }


  const HandleSubmit=async ()=>{
    setErrorMsg();
    setSaving(true)
    let contactsOld=businessAccount.contacts;


    let contacts=[];

    let contactSite=contactsOld.find(x=> x.type==5);
    if(contactSite){
      // site
      contacts.push({
        id:contactSite.id,
        value:business_contacts_site
      })
    }
   
    
    // telefone comercial
    contacts.push({
      id:contactsOld.find(x=> x.type==2).id,
      value:business_contacts_telefone
    })

    // e-mail comercial
    contacts.push({
      id:contactsOld.find(x=> x.type==1).id,
      value:business_contacts_email
    })
  
    let response = await postApi("/business-account/update",{
      general_business_activity_id:business_activity_id,
      general_business_monthly_billing_id:business_monthly_billing_id,
      business_account_contacts:contacts

    });

    response_run(response);
  }


  
    const response_run=(response)=>{
      if(response.success){
        alert("Dados alterados com sucesso");
      }else{

        if(response.general_business_activity_id){
          setErrorMsg(response.general_business_activity_id.toString())
        }else if(response.general_business_monthly_billing_id){
          setErrorMsg(response.general_business_monthly_billing_id.toString())
        }else if(response.business_account_contacts){
          setErrorMsg(response.business_account_contacts.toString())
        }else if(response.error){
          setErrorMsg(response.error.toString())
        }else{
          setErrorMsg("Ocorreu um erro. Verifique se todos os campos estão corretos.")
        }
      
      }
      setSaving(false)
    }



    const RevertTerminateAccountFunction=async ()=>{
      if(window.confirm("A solicitação de cancelamento será cancelada")){

      
       
      setRevertTerminating(true);
      let response = await postApi("/request/revert/cancel_account",{
      });
      if(response.error){
        alert(response.error.toString())
      }else{
        alert("Solicitação de cancelamento desfeita.");
        window.location.reload();
      }
    
      setRevertTerminating(false);
      window.location.reload();
    
    }
  
    }



    const handleOpenTerminateAccount = () => setOpenTerminateAccount(true);
    const handleCloseTerminateAccount = () => setOpenTerminateAccount(false);

  


  return (
    <React.Fragment>
      <Helmet title="Documentos" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Meus Dados
          </Typography>
          <Typography variant="subtitle1">
            {t("Preencha o formulário abaixo com seus dados comerciais ou de sua empresa.")}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

    </Grid>


      <Divider my={4} />
     
      
      
      
    
      <Grid container spacing={6} xs={12} sm={12} md={12}>
      
        <Grid item  md={window.isNativeApp?12:8}>
        <Card mb={6} >

            <Grid container justifyContent="center" >
              <Grid item>
              {!businessAccount.id &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid>

            {businessAccount.id &&
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Informações do seu Negócio 
              </Typography>
              <small>Conta {bs_Type==1?"Pessoal":"Empresarial"} - </small>
              {/*bs_Type==1 && <small>Deseja alterar o tipo de negócio? Clique aqui</small>*/}
              <br></br>

              <Grid container spacing={6}>
                <Grid item md={6} xs={12}>
                  <TextField
                  disabled
                    id="fullname"
                    label={bs_Type==1?"Nome Completo":"Razão Social"}
                    variant="outlined"
                    value={bs_company_name}
                    fullWidth
                    my={2}
                  />
                </Grid>
                
                <Grid item md={6} xs={12}>
                  
            <InputMask
              mask={bs_Type==1?"999.999.999-99":"99.999.999/9999-99"} 
              value={bs_Identifier}
              disabled={true}
              maskChar="_"
              label={bs_Type==1?"Seu CPF":"Seu CNPJ"}
            >
               {() => 
                <TextField   required
                  fullWidth
                  disabled
                  name="cpfcnpj"
                  label={bs_Type==1?"Seu CPF":"Seu CNPJ"}
                  type="text"
                 
                  id="cpfcnpj"
                />}
            </InputMask>
               
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={12} xs={12}>

                  <TextField
              disabled={true}
              required={true}
                    id="nomefantasia"
                    label={bs_Type==1?"Nome Comercial":"Nome Fantasia"}
                    variant="outlined"
                    type="text"
                    value={bs_fantasy_name}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={6} xs={12}>
                  <TextField
                  
              required={true}
                    id="phoneComercial"
                    label="Telefone Comercial"
                    variant="outlined"
                    value={business_contacts_telefone}
                    onChange={e=> setBusiness_contacts_telefone(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
                  <Grid item md={6} xs={12} >
                  <TextField
                  
              required={true}
                    id="phone"
                    label="E-mail Comercial"
                    variant="outlined"
                    value={business_contacts_email}
                    onChange={e=> setBusiness_contacts_email(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <TextField 
                    id="site" 
                    label="Site"
                    variant="outlined"
                    type="text"
                    value={business_contacts_site}
                    onChange={e=> setBusiness_contacts_site(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>

                <Grid item xs={12} my={2}>
                  <FormControl fullWidth  >
                  <InputLabel id="businessActivity">Atividade do seu negócio</InputLabel>
                  <Select fullWidth required
                    labelId="businessActivity"
                    id="businessActivity"
                    label="Atividade do seu negócio"
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 350 } },
                    }}
                    value={business_activity_id}
                      onChange={e=> setBusiness_activity_id(e.target.value)}
                    >
                      {  list_businessActivity.map(el => (
                          <MenuItem value={el.id} disabled={el.id==0}>{el.name}</MenuItem>
                        ))}
                    </Select>
                    {list_businessActivity.length==0 &&
                  <LinearProgress />}
                  </FormControl>
                </Grid>
              </Grid>


              <Grid container spacing={6}>

                <Grid item xs={12} my={2}>
                  <FormControl fullWidth  >
                  <InputLabel id="demo-simple-select-label">{business_monthly_billing_id?"":"Faturamento Mensal"}</InputLabel>
                    <Select fullWidth required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Faturamento ultimos 12 meses"
            
                      value={business_monthly_billing_id}
                      onChange={e=> setBusiness_monthly_billing_id(e.target.value)}
                    >
                      {  list_businessMonthlyBilling.map(el => (
                          <MenuItem value={el.id} disabled={el.id==0}>{el.name}</MenuItem>
                        ))}
                      
                    </Select>
                    {list_businessMonthlyBilling.length==0 &&
                  <LinearProgress />}
                  </FormControl>
                </Grid>
              </Grid>
              

              <br></br>

              <Button variant="contained" color="primary" mt={3} disabled={saving} onClick={HandleSubmit}>
                Salvar informações
              </Button>

              <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid>
            </CardContent>
            }

          </Card>
        </Grid>
     
        <Grid item md={window.isNativeApp?12:4}>
        <Card >
            <LogoMarkComponent />
          </Card>
        </Grid>

        <Grid item md={12} style={{marginTop:40}}>
        <Card >
          {
            (terminateAccountM &&
                  terminateAccountM.status_id==1 &&

                <React.Fragment>
                    <Button onClick={RevertTerminateAccountFunction} color="error" variant="contained"
                        disabled={revertTerminating}>Reverter encerramento de conta</Button>  
                    <Typography>Status da solicitação: {terminateAccountM.status_name}</Typography>
                </React.Fragment> 
            ) ||
            (
              terminateAccountM.length==0  &&

              <Button onClick={handleOpenTerminateAccount} 
              >Encerrar minha conta</Button>   
            )
          
          }

           

            <TerminateAccountBS 
            key={openTerminateAccount}
             openTerminateAccount={openTerminateAccount}
             handleCloseTerminateAccount={handleCloseTerminateAccount}
             handleOpenTerminateAccount={handleOpenTerminateAccount}
             />
          </Card>
        </Grid>

      </Grid>  
    </React.Fragment>
  );
}

export default Default;
