import { Button, TextField } from '@mui/material';
import React, { useEffect,useRef,useState } from "react";
import { MoreVertical } from 'react-feather';
import styled from "@emotion/styled";

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Fab as MuiFab,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ElectricCar } from '@mui/icons-material';
import {
  Add as AddIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Card = styled(MuiCard)(spacing);

const Fab = styled(MuiFab)(spacing);
const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);


export default function TblItensCreateBoleto({setItensCobranca,itensCobranca }) {


  useEffect(() => {
    let length=itensCobranca.length;
    if(length==0){

      addMoreItem();
    }
  },[]);
  
  const RemoveMoreItem = (x)=>{

    if(itensCobranca.length<2){
        return ;
    }
    let id=x.id;
    let newitemsTransacao = itensCobranca.filter(function(item) {
        return item.id !== id
    })
    setItensCobranca([...newitemsTransacao]);

}


const addMoreItem = ()=>{
    let lenght=itensCobranca.length;
    itensCobranca.push({
        id:lenght+1,
        description:null,
        qtd:"1",
        amount:0
    })

    setItensCobranca([...itensCobranca]);

}



const setDescricaoItem= (id,val) =>{
    
    let index=itensCobranca.findIndex(x=> x.id==id);
    itensCobranca[index].description=val;
    setItensCobranca([...itensCobranca])
}

const setQtdItem= (id,val) =>{


  
  let index=itensCobranca.findIndex(x=> x.id==id);
  itensCobranca[index].qtd=String(val).replace(/\D/g, "");
  setItensCobranca([...itensCobranca])
}



const setValorItem= (id,val) =>{
  val = String(val).replace(/\D/g, "");


  let index=itensCobranca.findIndex(x=> x.id==id);
  itensCobranca[index].amount=val/100;
  setItensCobranca([...itensCobranca])
}
const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <Card mb={6}>
    <CardHeader
      title="Itens da cobrança"
    />
    <Paper>
    <TableWrapper >
      <Table>
        <TableHead >
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Valor </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {itensCobranca.map( (el, index) => (
           <TableRow key={"frag"+el.id} >
            <Grid  style={{width:window.isNativeApp&&windowSize.current[0]/2.5}}>
           <TableCell component="th" scope="row" key={"desc"+el.id}  >
             <TextField
               placeholder={"Descrição do Item"}
               variant="outlined"
               fullWidth
               onChange={x => setDescricaoItem(el.id,x.target.value)}
                value={el.description}
                required
               my={2}
             />
           </TableCell>
           
           </Grid>
           <TableCell key={"val"+el.id}>
           <Grid 
                style={{width:window.isNativeApp&&windowSize.current[0]/4}}>
             <TextField
               placeholder={"Valor Unitário"}
               variant="outlined"
               value={parseFloat(el.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
               onChange={x => setValorItem(el.id,x.target.value)}
               fullWidth
               required
               my={2}
             />
            </Grid>
           </TableCell>
           <TableCell key={"rem"+el.id} >
           {itensCobranca.indexOf(el)>0 &&
             <Fab mx={2} color="primary" aria-label="Add" size="small"
               onClick={e=> RemoveMoreItem(el)}>
                <DeleteIcon />
             </Fab>
          }
           </TableCell>
         </TableRow>
         
          ))}
        </TableBody>
      </Table>
      <Fab mx={2} color="primary" aria-label="Add" size="small"
      onClick={e=> addMoreItem()}>
          <AddIcon />
        </Fab>
      
    </TableWrapper>
   
  </Paper>
  
  </Card>
  );
}

