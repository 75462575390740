import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Grid, Typography } from '@mui/material';
import useAuth from '../../../hooks/useAuth';

import {getUrlImage} from "../../../utils/infoUtils";
import { minWidth } from '@mui/system';




export default function SelectProvider({
  provider,setProvider,mobileProviders
}) {
  
 

  let { getApi,postApi ,deleteApi} = useAuth();



  const styleRadioChoosed={
    border:1,
    borderColor:"#d7dee0",
    width:window.isNativeApp?80:100
  };
  const styleRadio={
    width:window.isNativeApp?80:100
  };

  
  
  return (
    <FormControl>
 <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Selecione a sua operadora
        </Typography>      <RadioGroup
        row
        aria-labelledby="demo-form-control-label-placement"
        name="position"
        value={provider}
        onChange={e=> setProvider(e.target.value)}
        defaultValue="top"
      >
         <Grid style={{justifyContent:'center'}}>
     {  mobileProviders.map(el => (
         <FormControlLabel
        
          value={el.id}
         sx={provider==el.name? styleRadioChoosed:styleRadio}
          control={<Radio  />}
          label={getUrlImage(el.name)}
          labelPlacement="bottom"
        />
       
     ))}
      </Grid>

   
      </RadioGroup>
    </FormControl>
  );
}