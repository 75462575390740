import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
} from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  Link,
  ListSubheader,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { fontSize, spacing } from "@mui/system";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);





function IntegracaoAccess() {

  let { getApi,postApi } = useAuth();

const [loading, setLoading] = useState(false);

const Typography = styled(MuiTypography)(spacing);

const [integrationAccessM, setIntegrationAccessM] = React.useState([]);

const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

useEffect(() => {
  LoadAccess();
},[]);


const LoadAccess = async () => {
    
  setLoading(true);
  let response = await getApi("/integration/maintoken",{});
  
  setIntegrationAccessM(response);

  setLoading(false);

};




  return (
    <React.Fragment>
      <Helmet title="Portal do Cliente" />

      <Typography variant="h3" gutterBottom display="inline">
     Chaves de acesso - Integração
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="#">
        Integração
        </Link>
        <Typography>Acesso</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {loading &&
         
         <Grid container justifyContent="center" >
         <Grid item>
         <Box sx={{ display: 'flex' }}>
            <CircularProgress 
        alignItems="center"
        justifyContent="center" color="secondary" />
         </Box>
         </Grid>
       </Grid>


       }
      {!loading &&
        <React.Fragment>
     
        <center>
          <Typography variant="h5">Integre o seu sistema usando a nossa API.</Typography>
          <br></br>
          <a target="_blank" href="https://cachebank.stoplight.io/docs/cachebankcobrancav2/YXBpOjM1OTc2MTgx-boleto-pix-e-carne">
            Acesse a nossa documentação 
          </a>
          <br></br>
          <span>Para ter acesso a conta via API, você precisará usar os dados abaixos.</span>
        </center>
         
        <Grid sx={{justifyContent:'center'}} container >
          
      <TableContainer component={Paper} sx={{ width:'600px',  alignContent:"center"}}>
        <Table  size="medium" aria-label="a dense table">
             <TableBody>
            
                 <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >
                   <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                    <strong>Client Id</strong> 
                   </TableCell>
                   <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                     
                    {integrationAccessM.client_id}
                   </TableCell>
                 </TableRow>

                 <TableRow
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >
                   <TableCell component="th" scope="row" sx={{fontSize:14, fontFamily:FontFamily}}>
                    <strong>Client Secret</strong> 
                   </TableCell>
                   <TableCell align="left" sx={{fontSize:14, fontFamily:FontFamily}}>
                     
                   {integrationAccessM.client_secret}
                   </TableCell>
                 </TableRow>

                 </TableBody>
        </Table>
      </TableContainer>
      
      </Grid>
      <br></br>
      <br></br>
      <center>
        <span>
          Dúvidas? Fale com a gente via <a href="mailto:suporte@cachebank.com.br">suporte@cachebank.com.br</a></span>
        </center>
        </React.Fragment>
         
      }
    </React.Fragment>
  );
}

export default IntegracaoAccess;
