import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import TblDocumentos from "./TblDocumentos";


import UploadDocsComponent from "./UploadDocsComponent"
import UploadDocsComponentMobile from "./UploadDocsComponentMobile"



import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Button,
  CircularProgress,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import useAuth from "../../../hooks/useAuth";
import InformacaoCadastral from "./InformacaoCadastral";
import Link from '@mui/material/Link';
import CardActions from '@mui/material/CardActions';
import AppStoreIcon from "../../../assets/apple-store-icon.png"
import PlayStoreIcon from "../../../assets/google-store-icon.png"

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;




const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function Default() {
  const { t } = useTranslation();

  let { getApi,postApi } = useAuth();
  const [listDocumentsPending, setListDocumentsPending] = React.useState([]);
  const [listDocuments, setListDocuments] = React.useState([]);

  const [listUploadVal, setListUploadVal] = React.useState([]);

  
  const [isToSend, setIsToSend] = React.useState(false);

  
  const [loadingListDocumentPending, setLoadingListDocumentPending] = React.useState(true);




  useEffect(() => {

    LoadListDocument();
    LoadListDocumentPeding();
  },[]);

  

  const LoadListDocumentPeding = async () => {
    
    setLoadingListDocumentPending(true);
    let response = await getApi("/document/list/0");
    setListDocumentsPending(response.lista);
    LoadListDocument();
    setLoadingListDocumentPending(false);
  
  };

  const LoadListDocument = async () => {
    let response = await getApi("/document/list/-1");
    setListDocuments(response.lista);
  
  };



  const ConcluirEnvio = async (event) => {
    event.preventDefault();
    if(Object.keys(listUploadVal).length==0){
      alert("Você precisa selecionar os arquivos a serem enviados");
      return ;
    }

    setIsToSend(true)


  };



  return (
    <React.Fragment>
      <Helmet title="Documentos" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
           Análise Cadastral
          </Typography>
          <Typography variant="subtitle1">
            {t("Informações sobre envios de documentos e status da conta.")}
           
          </Typography>
        </Grid>

       
      </Grid>
      
      <InformacaoCadastral listDocumentsPending={listDocumentsPending} />


      {loadingListDocumentPending &&
         
         <Grid container justifyContent="center" >
         <Grid item>
         <Box sx={{ display: 'flex' }}>
            <CircularProgress 
        alignItems="center"
        justifyContent="center" color="secondary" />
         </Box>
         </Grid>
       </Grid>


       }


      {listDocumentsPending.length>0 && 
       <Divider my={6} />}
     
     {listDocumentsPending.length>0 && 
      window.isNativeApp==false &&
        <React.Fragment>
             
      <Grid justifyContent="center"  container sx={{ marginTop:20}}>
      <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Baixe o nosso App
        </Typography>
        <Typography variant="h5" component="div">
          Valide a sua conta usando o nosso APP
        </Typography>
        <Typography  color="text.secondary">
          Disponivel para Android e IOS.
        </Typography>
      </CardContent>
      <CardActions>
        <Link href='https://play.google.com/store/apps/details?id=com.cachebank&hl=pt_BR' target={"_blank"}>
        <img src={PlayStoreIcon} height={50}/>
          </Link>
        <Link href='https://apps.apple.com/br/app/id1617702585' target={"_blank"}>
            <img src={AppStoreIcon} height={40}/>
        </Link>

      </CardActions>

    </Card>

      </Grid>

        </React.Fragment>
    }
      
      <Stack
        direction="row" 
        alignItems="center"
        justifyContent="center"
        spacing={0}
        sx={{ flexWrap: 'wrap', gap: 1 }}
         >
        {  listDocumentsPending.map(el => (
        <Item> 
          <Card sx={{ minWidth: 375 }}>
            <CardContent>
              
              <Typography variant="h6" component="div">
                {el.document_type.description_obj["short"]}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {el.document_type.description_obj["long"]}
              </Typography>
              <Typography variant="body2">
                {el.document_type.description_obj["mid"]}
                <br />
              </Typography>
            </CardContent>
            
            {
              window.isNativeApp &&

              <UploadDocsComponentMobile key={el.id} requestedDoc={el} 
              LoadListDocumentPeding={LoadListDocumentPeding}
              isToSend={isToSend}
              setIsToSend={setIsToSend}
                datachange={e=> { 
                  var b='doc'+el.id;

                  let mp={...listUploadVal, ['value_doc'+el.id]:e };
           
                  setListUploadVal(mp);
                }
              
            
              } />

            }

            {
              !window.isNativeApp &&

              <UploadDocsComponent key={el.id} requestedDoc={el} 
              LoadListDocumentPeding={LoadListDocumentPeding}
              isToSend={isToSend}
              setIsToSend={setIsToSend}
                datachange={e=> { 
                  var b='doc'+el.id;

                  let mp={...listUploadVal, ['value_doc'+el.id]:e };
           
                  setListUploadVal(mp);
                }
              
            
              } />

            }
              
            


          </Card>
    
    
        </Item>

        ))}
        
      </Stack>

      
      {listDocumentsPending.length>0 &&
      
      <Grid justifyContent="center"  container sx={{ marginTop:20}}>
      <Button 
        alignItems="center"
        justifyContent="center" variant="contained" color="secondary" disabled={false} onClick={ConcluirEnvio}>Enviar Documentação</Button>
      </Grid>
      }





      <Divider my={4} />
      <TblDocumentos listDocuments={listDocuments}/>

      { window.isNativeApp &&    
      <Grid container item xs={12} md={6} justifyContent="flex-end" marginTop={10}>
          <List>
            <ListItemButton onClick={() => window.location.href="https://www.cachebank.com.br/wp-content/uploads/2023/01/atualizado6.pdf"}>
              <ListItemText primary={`Termo de Uso`} />
            </ListItemButton>
            <ListItemButton onClick={() => window.location.href="https://www.cachebank.com.br/politica-de-privacidade"}>
            
              <ListItemText primary={`Termo de Privacidade`} />
            </ListItemButton>
          </List>
        </Grid>
      }

    </React.Fragment>
  );
}

export default Default;
