import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,postApi } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(5);
const [totalRecords, setTotalRecords] = useState(0);

const [openEditClient, setOpenEditClient] = useState(false);
const [selectedEditValue, setSelectedEditValue] = useState([]);


const [arrayResult, setArrayResult] = React.useState([]);


const handleClickOpenEditClient = (selectedEditValue) => {
  setOpenEditClient(true);
  setSelectedEditValue(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenEditClient(false);
  setSelectedEditValue(value);
};

const navigate = useNavigate();

const ColorButtonTransaction =(status)=>{
  let color="primary";
  if(status==0){
    color="boot_warning"
  }else if(status==1){
    color="boot_success"
  }else if(status==2){
    color="boot_info"
  }else if(status==3){
    color="boot_secondary"
  }else if(status==4){
    color="boot_dark"
  }else if(status==5){
    color="boot_danger"
  }else if(status==6){
    color="boot_dark"
  }else if(status==7){
    color="boot_success"
  }else if(status==8){
    color="boot_success"
  }else if(status==9){
    color="boot_success"
  }else if(status==10){
    color="boot_secondary"
  }

  return color;
}

const columns = [
  {
    field: "billing_business_invoice_id",
    headerName: "ID Cobrança",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      <React.Fragment>
       <Link
          onClick={e => navigate("/cobrancas/show/"+params.row.billing_business_invoice_id)}>
          {params.row.id?params.row.billing_business_invoice_id:null}
        </Link>
    </React.Fragment>
    }
  },
  {
    field: "created_at",
    headerName: "Criado em",
    sortable: false,
    width: 150,
    editable: false,
    renderCell: (params) => {
      let DateC=new Date(params.row.created_at);
      return <div className="rowitem"> {
        DateC.toLocaleDateString('pt-br')
        +" "+DateC.getHours()+":"+DateC.getMinutes()+":"+DateC.getSeconds()
        
        }</div>;
    }}, 
    {
      field: "Tipo de envio",
      headerName: "Tipo",
      sortable: false,
      width: 100,
      editable: false,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.push_type} </div>;
      }},
  {
    field: "fullname",
    headerName: "Cliente",
    sortable: false,
    width: 290,
    editable: false,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.costumer.fullname}</div>;
  }
  },
  {
    field: "tax_identifier",
    headerName: "CPF/CNPJ",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.costumer.tax_identifier}</div>;
    }
  },
  {
    field: "fees",
    headerName: "Taxa",
    sortable: false,
    width: 110,
    renderCell: (params) => {
      if(params.row.fees==0){
        return <div className="rowitem">Grátis</div>;

      }else if(params.row.is_charge==false){
        return <div className="rowitem">Grátis</div>;

      }else{
        return <div className="rowitem">{parseFloat(params.row.fees).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>;
      }
    }
  },
  {
    field: "message",
    headerName: "Mensagem",
    sortable: false,
    width: 280,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.message}</div>;

    }
  },
  
  
  
];



const [statusList, setStatusList] = useState([]);

const [search_Status, setSearchStatus] = useState(-1);
const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("all");
const [query_filterDateType, setQuery_filterDateType] = useState(1);

const [query_filterDateStart, setQuery_filterDateStart] = useState(null);
const [query_filterDateEnd, setQuery_filterDateEnd] = useState(null);


function LoadQueryParams(limit_arg,page_arg){

  let invoiceTmp={};
  
  invoiceTmp["limit"]=limit_arg;
  invoiceTmp["page"]=page_arg;
  invoiceTmp["minimal_data"]=true;

  invoiceTmp["queryString"]={
    "notification_push_type":query_type
    
  };





  return invoiceTmp;

}

useEffect(() => {

  LoadMyClients();
},[]);




const LoadMyClients = async (page_arg=null, limit_arg=null) => {
    

  setListCobr([]);
  setLoadingList(true);
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await postApi("/automation/sent/list", Arrayp);
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListCobr(response.lista.map(x=> x.history));
  setLoadingList(false);

};


  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>

<Divider my={4} />
      <Grid container spacing={6}>
                
                <Grid item md={4}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de automação</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de automação"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"all"}>Todas automações</MenuItem>
                          <MenuItem value={"email"}>Automações de E-mail</MenuItem>
                          <MenuItem value={"sms"}>Automações de SMS</MenuItem>
                          <MenuItem value={"whatsapp"}>Automações de Whatsapp</MenuItem>
                          <MenuItem value={"voice"}>Automações de Voz</MenuItem>
                        
                      </Select>
                    </FormControl>
                   
                </Grid>
              </Grid>

             
              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadMyClients(0)}>
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
        <div style={{ height: (limitPage==5?400:600), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            rows={listCobr}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}  
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
            
          />
          

    
        </div>
      </Paper>
    </Card>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Automações enviadas" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Automações enviadas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/cobrancas">
          Automações enviadas
        </Link>
        <Typography>Listagem</Typography>
      </Breadcrumbs>
      </Grid>

      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />
     
       
       
    </React.Fragment>
  );
}

export default DataGridPage;
