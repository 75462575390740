import React, { Component, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment,IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { FormControl, FormControlLabel, FormLabel, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, Stack } from '@mui/material';
import {estadosBrasileiros} from '../../estados-brasileiros'

function PageSignUpStep3() {
  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();
const navigate = useNavigate();


let [saving, setSalving] = useState(false);
let [errormsg, setErrorMsg] = useState('');

let [city, setCity] = useState('');
let [stateCountry, setStateCountry] = useState('');
let [district, setDistrict] = useState('');
let [number, setNumber] = useState('');
let [street, setStreet] = useState('');
let [zipcode, setZipCode] = useState('');
let [complement, setComplement] = useState('');


let [loadingcep, setLoadingCep] = useState(false);

useEffect(() => {


},[]);





const checkValues= ()=>{
  if(!city){
    alert("Você precisa preencher a cidade");
  }else if(!stateCountry){
    alert("Você precisa preencher o seu estado");
  }else if(!district){
    alert("Você precisa preencher a seu bairro");
  }else if(!number){
    alert("Você precisa preencher o numero");
  }else if(!street){
    alert("Você precisa preencher o seu logradouro");
  }else if(!zipcode){
    alert("Você precisa preencher o seu CEP");
  }else{
    return true;
  }
}




const handleSubmit = async (event) => {
  event.preventDefault();
  setErrorMsg(null)
  setSalving(true)
  if(!checkValues()){
    setSalving(false)
    return false;
  }

    
  let response = await postApi("/signup/step3",{
    city,
    state:stateCountry,
    number:number.replace(/\D/g, ""),
    district,
    street,
    zipcode:zipcode.replace(/\D/g, "")
  });

  response_run(response);



};

const response_run=(response)=>{
  if(response.success){
    window.location.href="/minha-conta/documentos";

  }else{

    if(response.city){
      setErrorMsg(response.city.toString())
    }else if(response.state){
      setErrorMsg(response.state.toString())
    }else if(response.district){
      setErrorMsg(response.district.toString())
    }else if(response.street){
      setErrorMsg(response.street.toString())
    }else if(response.number){
      setErrorMsg(response.number.toString())
    }else if(response.complement){
      setErrorMsg( response.complement.toString())
    }else if(response.zipcode){
      setErrorMsg( response.zipcode.toString())
    }
    else if(response.country){
      setErrorMsg( response.country.toString())
    }else{
      setErrorMsg( response.error)
    }
    setSalving(false)
  }
}


const serachZipCode=async ()=>{
  setLoadingCep(true)
  let zipcodeTmp=String(zipcode).replace(/\.|\-/g, '');

  if(zipcodeTmp.length!=8){
    setLoadingCep(false) 
    return false;
  }
 
  const response = await getApi("/consultar/cep/"+zipcodeTmp);


  setCity(response.city);
  setStreet(response.street);
  setDistrict(response.district)
  setStateCountry(response.state)
  setLoadingCep(false) 
 
}

  

  return (
    <React.Fragment>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'100%'
          }}
        >
          <img src="https://pubstore1.cachebank.com.br/logos/v2/Logomarca-%20Azul.png" width="180" />
          <br></br>
          <Typography  variant="h6">
            Informações sobre endereço
          </Typography>
          <Typography variant="caption" gutterBottom>
          Preencha com as informações sobre o local do seu negócio.
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
             

              <Grid item xs={12}>
              <Typography variant="caption" gutterBottom>
                Informações sobre endereço do negócio
              </Typography> 
              </Grid>
              <Grid item xs={6}>
           

                <InputMask
                  mask="99.999-999"
                  disabled={false}
                  maskChar="_"
                  label="Seu CEP"
                  value={zipcode}
                  onChange={e => setZipCode(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="cep"
                  label="Informe o seu CEP"
                  type="text"
                  id="cep"
                  autoComplete="cep"
                />}
                </InputMask>
           
             </Grid>
             <Grid item xs={6}>
          
             <Button variant="outlined" onClick={e => serachZipCode()} disabled={loadingcep}>Preencher</Button>
             {loadingcep==true &&
                <LinearProgress />}
           
             </Grid>


             <Grid item xs={7}>
          
             <TextField   required
                  fullWidth
                  name="cnpj"
                  placeholder="Informe o seu Logradouro"
                  type="text"
                  id="logradouro"
                  autoComplete="logradouro"
                  value={street}
                  onChange={e => setStreet(e.target.value)}
                />
            
              </Grid>
              <Grid item xs={5}>
          
              <TextField   required
                  fullWidth
                  name="numero"
                  label="Nº"
                  type="text"
                  id="numero"
                  autoComplete="numero"
                  value={number}
                  onChange={e => setNumber(e.target.value)}
                />
            
              </Grid>


              <Grid item xs={7}>
          
             <TextField  
                  fullWidth
                  name="complement"
                  label="Complemento"
                  type="text"
                  id="Complemento"
                  autoComplete="Complemento"
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
                />
            
              </Grid>
              <Grid item xs={5}>
          
              <TextField   required
                  fullWidth
                  name="bairro"
                  placeholder="Bairro *"
                  type="text"
                  id="bairro"
                  autoComplete="bairro"
                  value={district}
                  onChange={e =>setDistrict(e.target.value)}
                />
            
              </Grid>



              <Grid item xs={7}>
          
          <TextField   required
               fullWidth
               name="Cidade"
               placeholder="Cidade *"
               type="text"
               id="Cidade"
               autoComplete="Cidade"
               value={city}
               onChange={e => setCity(e.target.value)}
             />
         
           </Grid>
           <Grid item xs={5}>
       
           <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">{stateCountry?"":"Estado *"}</InputLabel>
                  <Select fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stateCountry}
                    onChange={e=> setStateCountry(e.target.value)}
                  >
                     {  estadosBrasileiros.UF.map(el => (

                      <MenuItem value={el.sigla}>{el.sigla}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
         
           </Grid>
       
          
          

              


             
            </Grid>

            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={saving}>
             Continuar
            </Button>
       
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default (PageSignUpStep3);
