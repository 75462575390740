import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CarneEdit from "../../Carne/edit"
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  TextField,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import TableContainer from '@mui/material/TableContainer';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function ScreenComponent({costumer}) {
let { getApi,postApi,deleteApi } = useAuth();
const [attachmentList, setAttachmentsList] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);

  const [fileBase64, setFileBase64] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [loadingUpload, setLoadingUpload] = React.useState(false);

  const [generatingLink, setGeneratingLink] = React.useState(false);

const [addAttach, setAddAttach] = React.useState(false);

useEffect(() => {
  if(costumer){
    LoadAttachments();
  }

}, [costumer]);


  const SetFile = async (e) => {
    
    console.log(e.target.files[0]);
    if(e.target.files[0]){
      setFileName(e.target.files[0]["name"]);
    }

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function(e) {
        let base64Image = String(reader.result).replace(/^data:image\/[a-z]+;base64,/, "");
        
        setFileBase64(base64Image);
        

    };
  
  };

  const Save = async (e) => {
    
    setLoadingUpload(true);
    let response = await postApi("/costumer/"+costumer.id+"/attach/",{
      file:fileBase64,
      description:description,
      filename:fileName
    });

    if(!response.success){
      let error=response.error;
      if(error){
        alert(error.toString());
      }else{
        if(response.description){
          alert(response.description.toString());
          
        }else if(response.file){
          alert(response.file.toString());
        }

      }

    }else{
     setAddAttach(false);
     setDescription(null);
     setFileBase64(null);
     LoadAttachments();
    }


  
    setLoadingUpload(false);
   
  
  };

  const LoadAttachments = async () => {
    
    setLoadingList(true);
    let response = await getApi("/costumer/"+costumer.id+"/attach/",{});
    
    setAttachmentsList(response.lista);
    setLoadingList(false);
  
  };


  const generateTempLink = async (attachId) => {
    setGeneratingLink(true);
    setLoadingList(true);
    let response = await getApi("/costumer/"+costumer.id+"/attach/"+attachId+"/temporaryLink",{});
    
    window.open(response, '_blank').focus();
    setGeneratingLink(false);
    setLoadingList(false);
  };

  const deleteAttachment = async (el) => {

    if (!window.confirm(
        "O arquivo: será excluido.\n"+"Descrição: "+el.description+"\nId:"+el.filename
    )) {
      return ;
    }
    setGeneratingLink(true);
    setLoadingList(true);
    let response = await deleteApi("/costumer/"+costumer.id+"/attach/"+el.id,{});
    
    if(!response.success){
      let error=response.error;
      if(error){
        alert(error.toString());
      }else{
        if(response.description){
          alert(response.description.toString());
          
        }else if(response.file){
          alert(response.file.toString());
        }

      }

    }
    setGeneratingLink(false);
    LoadAttachments();
  };


  return (
    <Card mb={6}>
     
 
     <Grid item md={3}>
     <TableContainer component={Paper}>
      <Table sx={{ maxWidth: 950 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell align="left">Nome do arquivo</TableCell>
            <TableCell align="left">Dt. envio</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
          {attachmentList &&
          attachmentList.map(el=>
            
            <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
              <TableCell component="th" scope="row" >
              {el.description}
              </TableCell>
              <TableCell align="left"  onClick={e=> generateTempLink(el.id)}>
                {(generatingLink || loadingList)?
                <a>{el.filename}</a>
                :
                <a href="#">{el.filename}</a>
                }
              </TableCell>
              <TableCell align="left"> {new Date(el.created_at).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
              <TableCell align="left">
              <Button
                variant="contained"
                size="small"
                color={"primary"}
                onClick={e=> generateTempLink(el.id)}
                disabled={generatingLink||loadingList}>
                Baixar
              </Button>
              <Button
                variant="contained"
                size="small"
                color={"boot_danger"}
                disabled={generatingLink||loadingList}
                onClick={e=> deleteAttachment(el)}>
                Deletar
              </Button>
              </TableCell>
            </TableRow>
            
            )}
         
            {loadingList && 
                  <Grid item>
                  <Box sx={{ display: 'flex' }} justifyContent="center">
                    <CircularProgress size={40} />
                  </Box>
               </Grid>
               }
            {
              addAttach &&
              <React.Fragment>
                <TableRow 
                sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}>
                <TableCell component="th" scope="row">
                  <TextField
                    required={true}
                    id="nomefantasia"
                    label={"Descrição"}
                    variant="outlined"
                    type="text"
                    value={description}
                    onChange={e=> setDescription(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </TableCell>
                <TableCell align="left">
              
                  <input type="file" 
                  onChange={SetFile} 
                    accept="image/*,audio/*,video/*,application/*,text/*" />
                    <br></br>
                  <small>Tamanho máximo permitido: 25MB</small>

                </TableCell>
                  <TableCell align="left">
                  <Button
                    variant="contained"
                    size="small"
                    color={"primary"}
                    onClick={Save}
                    disabled={loadingUpload}>
                    Adicionar
                  </Button>
                  </TableCell>
              
              </TableRow>
                {loadingUpload && 
                  <Grid item>
                  <Box sx={{ display: 'flex' }} justifyContent="center">
                    <CircularProgress size={20} />
                  </Box>
               </Grid>
               }
              </React.Fragment>
            }
        </TableBody>
      </Table>
    </TableContainer>
    {!loadingList &&
      <Fab mx={2} color="primary" aria-label="Add" size="small"
     onClick={e=> setAddAttach(!addAttach)}>
          <AddIcon />
        </Fab>
    }
                </Grid>

      <Paper>
        
      </Paper>
    </Card>
  );
}

function AttachmentsCostumerBusiness({costumer}) {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>

      <Divider my={6} />
  
      <ScreenComponent costumer={costumer}/>

       
       
    </React.Fragment>
  );
}

export default AttachmentsCostumerBusiness;
