import React, { Component, useEffect, useState } from "react";
import styled from "@emotion/styled";
import "./style-modal.css"
import { Badge, Grid, Avatar, Typography, Modal, Dialog, Slide, DialogTitle, DialogContent, DialogContentText } from "@mui/material";

import useAuth from "../../hooks/useAuth";
import Box from '@mui/material/Box';

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const { user, getApi,postApi } = useAuth();
  const [openOneClickWhats, setOpenOneClickWhats] = React.useState(false);
  const [loadingBTNWhatsApp, setLoadingBTNWhatsApp] = React.useState(false);


  let location=window.location.href;
  const closeModal=()=> {
    setOpenOneClickWhats(false);
    localStorage.setItem('disable_modal_automacaowhatsapp', 1);
  }
  
 
  const checkisAutomacaoWhatsapp=async ()=>{
    let response = await getApi("/count/automation/whatsapp");
    if(response==0 && localStorage.getItem('disable_modal_automacaowhatsapp')==null){
      setOpenOneClickWhats(true);
    }else{
      localStorage.setItem('disable_modal_automacaowhatsapp', 1);
    }
  }

  const createRoutineWhatsAppOneClickForOverdue=async ()=>{
    if(loadingBTNWhatsApp){
      return;
    }
    setLoadingBTNWhatsApp(true);
    let response = await postApi("/oneclick/automation/whatsapp/create/overdue");
    if(response.success){
      window.location.href="/automacao/whatsapp";
   
    }else{
      setLoadingBTNWhatsApp(false);
    }
    
  }

  
  if(String(location).includes("#oneclickRoutineWhatsApp")){
    createRoutineWhatsAppOneClickForOverdue();
  }


  useEffect(() => {
    if(user){
        checkisAutomacaoWhatsapp();
    }
  
  }, [user]);


  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {!!user && <Avatar alt={user.firstname} src={user.avatar} />}
            {/* Demo data */}
            {!user && (
              <Avatar
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            )}
          </FooterBadge>
        </Grid>
        <Grid item>
          {!!user && (
            <FooterText variant="body2">{user.firstname}</FooterText>
          )}
          {/* Demo data */}
          {!user && <FooterText variant="body2">{user.firstname}</FooterText>}
          <FooterSubText variant="caption">Administrador</FooterSubText>
        </Grid>
      </Grid>
      <Dialog
        open={openOneClickWhats}
        keepMounted
        onClose={closeModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle textAlign={'center'}>{"Já conhece a cobrança via WhatsApp?"}</DialogTitle>
        <DialogContent>
        <hr />
<p><strong>Envie até 300 mensagens grátis</strong></p>

<p>Aumente sua conversão durante o teste. Ative sem compromisso.</p>

<p></p>
<hr />
<p><strong>Link de Pagamento</strong></p>

<p>Quando a fatura vencer, seus clientes receberam um mensagem no whatsApp pedindo que o pagamento seja realizado.</p>

<p></p>

<p><strong>Não se desgate realizando cobrança, facilite a sua vida e do seu cliente.</strong></p>

<center><a href="/automacao/whatsapp#oneclickRoutineWhatsApp"  class={loadingBTNWhatsApp?"ativarwhatsappbtnmodal disabledBTN":"ativarwhatsappbtnmodal"}>
  {loadingBTNWhatsApp?"Ativando":"Ativar notificação via whatsapp"}</a></center>
<p>Clicando acima, os seus clientes receberão via WhatsApp as próximas faturas que estiverem com 3 dias em atraso.</p>
        </DialogContent>
      </Dialog>


    </Footer>
  );
};

export default SidebarFooter;
