import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Tooltip } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { styled } from "@mui/system";
import TextareaAutosize from '@mui/base/TextareaAutosize'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

 


function TerminateAccountBSComponent({openTerminateAccount,handleCloseTerminateAccount,handleOpenTerminateAccount}) {
  const [checked, setChecked] = React.useState([]);
  let { getApi,postApi,user,businessAccount } = useAuth();
  const [terminating, setTerminating] = React.useState(false);
  const [motivo, setMotivo] = React.useState();
  const [observacao, setObservacao] = React.useState('');


    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      console.log(newChecked);
      setChecked(newChecked);
    };



    const TerminateAccount=async ()=>{
      setTerminating(true);
      let response = await postApi("/request/cancel/account",{
        reason:motivo,
        obs:observacao
      });
      console.log(response)
      if(response.message){
        alert(response.message.error.toString())
      }else{
        alert("Solicitação de cancelamento efetivado com sucesso");
        window.location.reload();
      }
    
      setTerminating(false);
  
    }
    
    
  return (
    <React.Fragment>

<div>
      <Modal
        open={openTerminateAccount}
        onClose={handleCloseTerminateAccount}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Encerrar minha conta.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           Marque as opções abaixo se deseja continuar.
           <br></br> Entendo que:
          </Typography>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {[
        {id:1, text:'Todas as cobranças pendentes serão canceladas em até 72h.'},
        {id:2, text:'Não poderá haver saldo disponivel ou bloqueado remanescente.'},
        {id:3, text:'Após solicitar, o cancelamento pode ocorrer em até 5 dias úteis.'},
        {id:4, text:'A comunicação do cancelamento da conta ocorrerá via E-mail ou SMS cadastrado.'},
        {id:5, text:'Estou ciente que isso não pode ser revertido, a partir de 48h.'}
      ].map((value) => {
        const labelId = `checkbox-list-label-${value.id}`;

        return (
          <ListItem
            key={value.id}
            disablePadding
          >
            <ListItemButton role={undefined}  dense>
              <ListItemIcon onClick={handleToggle(value.id)}>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.text} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
    <br></br>
    <Grid item md={12}>
      <FormControl fullWidth  >
      <InputLabel id="demo-simple-select-label">Selecione um motivo</InputLabel>
        <Select fullWidth required
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Status do pagamento Pagamento"
          onChange={e => setMotivo(e.target.value)}
          value={motivo}
        >
        <MenuItem value={"Estou fechando meu negócio"}>Estou fechando meu negócio</MenuItem>
        <MenuItem value={"Tive problemas/dificuldades com a plataforma"}>Tive problemas/dificuldades com a plataforma</MenuItem>
        <MenuItem value={"Encontrei taxas mais atrativas"}>Encontrei taxas mais atrativas</MenuItem>
        <MenuItem value={"Sinto falta de alguma funcionalidade"}>Sinto falta de alguma funcionalidade</MenuItem>
        <MenuItem value={"Vou abrir outra conta na Cachê Bank"}>Vou abrir outra conta na Cachê Bank</MenuItem>
        <MenuItem value={"Outros"}>Outros</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <br></br>
    <Grid item md={12}>
      <FormControl fullWidth  >
        <TextareaAutosize  
        onChange={e=> setObservacao(e.target.value)}
        placeholder="Observação"
        value={observacao}
         minRows={4}/>
      </FormControl>
    </Grid>
    <br></br>
    <Tooltip title={checked.length!=5?"Marque as opções acima":"Se tiver certeza, clique aqui."}>
      <span>
      <Button key={openTerminateAccount} onClick={TerminateAccount}  color={"error"}
          variant={"contained"}
          disabled={checked.length!=5 || terminating==true} arrow>Encerrar minha conta
      </Button> 
      </span>
    </Tooltip>
        </Box>
      </Modal>
    </div>
    </React.Fragment>
  );
}

export default TerminateAccountBSComponent;
