import AlgarTelecomIco1 from "../assets/providers/algar-telecom.png";
import CorreioCelularIco from "../assets/providers/correio-celular.webp";
import TimCelularIco from "../assets/providers/tim.webp";
import VivoCelularIco from "../assets/providers/vivo-celular.webp";
import ClaroCelularIco from "../assets/providers/claro-celular.png";
import SerComTelIcon from "../assets/providers/sercomtel.png"
import SurfTelecomIcon from "../assets/providers/surf-telecom.webp"

const getUrlImage= (provider, width=null)=>{
  if(provider=="CLARO"){
      return <img src={ClaroCelularIco} alt="claro-icon" style={{
        width: "50px" }} />;
  }else if(provider=="VIVO"){
    return <img src={VivoCelularIco} alt="vivo-icon" style={{width: width?width:"80px", marginTop:8}} />
  }else if(provider=="TIM"){
    return <img src={TimCelularIco} alt="tim-icon" style={{width: width?width:"80px"}} />;
  }else if(provider=="Correio Celular"){
    return <img src={CorreioCelularIco} alt="correio-celular-icon" style={{width: width?width:"80px"}} />;
  }else if(provider=="Algar Celular"){
    return <img src={AlgarTelecomIco1} alt="algar-telecom-icon" style={{width: width?width:"70px"}} />;
  }else if(provider=="SERCOMTEL"){
    return <img src={SerComTelIcon} alt="sercom-telecom-icon" style={{width: width?width:"90px"}} />;
  }else if(provider=="Surf Telecom"){
    return <img src={SurfTelecomIcon} alt="surf-telecom-icon" style={{width: width?width:"80px"}} />;
  }
} 

export { getUrlImage };
