import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import {estadosBrasileiros} from '../../../../estados-brasileiros'

import { useNavigate } from "react-router-dom";


function AddNewClient(props) {
  let { getApi,postApi,user,signUpStep2,isBSAccount,isInitializedBS } = useAuth();

  const { onClose, selectedValue, open } = props;
  let [identifier, setIdentifier] = useState('');
  let [fullname, setFullname] = useState('');
  let [email, setEmail] = useState('');
  let [reference, setReference] = useState('');

  useEffect(() => {
    if(selectedValue.tax_identifier){
      setIdentifier(selectedValue.tax_identifier)
      setFullname(selectedValue.fullname)
      setEmail(selectedValue.email)
      setPhone(selectedValue.phone);
      setReference(selectedValue.reference);
      setId(selectedValue.id);
    }
   


    const LoadDataCostumer=async ()=>{
      const response = await getApi("/costumer/"+selectedValue.id);
      if(response.costumer){
        setIsUserAddress(response.costumer.is_address_user);

        
        setLoadingAddress(false)
        
      }
    }

    LoadDataCostumer();


  },[selectedValue]);

  const handleClose = () => {
    onClose(selectedValue);
    window.location.reload();

  };

  let [saving, setSaving] = useState(false);
  let [loadingAddress, setLoadingAddress] = useState(true);
  let [errormsg, setErrorMsg] = useState('');

  let [id, setId] = useState('');
  let [phone, setPhone] = useState('');

  let [is_user_address, setIsUserAddress] = useState(false);
  let [generateRandomPass, setGenerateRandomPass] = useState(0);
  let [newPassword, setNewPassword] = useState('');
  let [confirmNewPass, setConfirmNewPass] = useState('');








const checkValues=()=>{
  if(generateRandomPass==1 && !newPassword){
    alert("Para alterar a senha manual, será preciso informar-lá.");
  }else if(generateRandomPass==1 && newPassword!=confirmNewPass){
    alert("As senhas não são identicas.");
  }
  else{
    return true;
  }
}

const handleSubmit = async (event) => {
  event.preventDefault();
  setSaving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSaving(false);
    setErrorMsg('')
    return false;
  }

   let response = await postApi("/allstore/update/user/password",{
    newPassword:generateRandomPass==0?null:newPassword,
    costumer_id:id,


   });
 
  response_run(response);

};
const navigate = useNavigate();


const response_run=(response)=>{
  if(response.success){
    alert("Senha alterada com sucesso. \nPode levar até 3 minutos para ter efeito.");
    setSaving(false)
  }else{
    if(response.error.newPassword){
      setErrorMsg("Senha pode ser inválida")
    }else if(response.error.costumer_id){
      setErrorMsg("O usuário não foi encontrado.")
    }else {
      setErrorMsg( response.error)

    }
     
    
    setSaving(false)
  }
}

  

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="simple-dialog-title" ><center>Alterar Senha - {fullname}</center></DialogTitle>
       
    
      <Grid container spacing={0}>
        <Grid item xs={12} >
        <Card mb={1} >

            <Grid container justifyContent="center" >
              <Grid item>
             
              </Grid>
            </Grid>

            <CardContent>

              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Nome Cliente *</label>
                  <TextField
                    id="fullname"
                    placeholder={"Nome ou razão social"}
                    variant="outlined"
                    value={fullname}
                    disabled={true}
                    onChange={e => setFullname(e.target.value)}
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>

                <label for="my-input">CPF/CNPJ *</label>
              
                <TextField   required
                  fullWidth
                  name="cpf_cnpj"
                  type="text"
                  id="cpf_cnpj"
                  autoComplete="cpf_cnpj"
                  placeholder="CPF/CNPJ"
                  disabled={true}
                  value={identifier}
                  onChange={e => setIdentifier(e.target.value)}
                  
                  
                  my={2}
                />

                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Endereço de e-mail *</label>
                  <TextField
                    id="fullname"
                    placeholder={"E-mail"}
                    variant="outlined"
                    value={email?email:"Não definido"}
                    fullWidth
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    my={2}
                    type="email"
                  />
                </Grid>
              </Grid>
            
              <Grid container spacing={6} textAlign={"center"}>
                <Grid item md={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={generateRandomPass}
                    onChange={e=> setGenerateRandomPass(e.target.value)}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="Gerar nova senha" />
                    <FormControlLabel value={1} control={<Radio />} label="Alterar Senha" />
                  </RadioGroup>
                </FormControl>
                </Grid>
              </Grid>

            {generateRandomPass==1 &&
                <Grid container spacing={6}>
                <Grid item md={12}>
                <label for="my-input">Informe a nova senha*</label>
                  <TextField
                    id="newPass"
                    placeholder={"Nova Senha"}
                    variant="outlined"
                    value={newPassword}
                    fullWidth
                    onChange={e => setNewPassword(e.target.value)}
                    my={2}
                    type="password"
                  />
                </Grid>
                <Grid item md={12}>
                <label for="my-input">Confirme a senha*</label>
                  <TextField
                    id="newPass"
                    placeholder={"Nova Senha"}
                    variant="outlined"
                    value={confirmNewPass}
                    fullWidth
                    onChange={e => setConfirmNewPass(e.target.value)}
                    my={2}
                    type="password"
                  />
                </Grid>
              </Grid>
            }

            <Typography color={"secondary"} mt={2} textAlign={"center"}>
             {generateRandomPass==0 &&
              <React.Fragment>
                Isso vai alterar a senha de acesso do seu cliente usando uma senha aleatória.
              </React.Fragment>
            }
            {generateRandomPass==1 &&
              <React.Fragment>
                Isso vai alterar a senha de acesso do seu cliente usando a senha informada.
              </React.Fragment>
            }
            </Typography>

            <Typography color={"secondary"} mt={2} textAlign={"center"}>
             {email==null &&
              <React.Fragment>
                Seu cliente não receberá um e-mail, por não ter e-mail definido.
              </React.Fragment>
            }
            </Typography>

              


            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
              <br></br>
              <Button variant="contained" disabled={saving || loadingAddress} color="primary" mt={3} onClick={handleSubmit} >
                Atualizar Senha
              </Button>
              <span>{errormsg}</span>
              
              
            </CardContent>

        </Card>
        </Grid>
        </Grid>
                




    </Dialog>
  );
}


export default AddNewClient;
