import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";

const Session_BusinessAccountId="@CacheBank_BusinessAccount"


//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//   }, timeLeft);
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("@CacheBank_");
    delete axios.defaults.headers.common.Authorization;
  }
};
const setBusinessAccount = (CacheBank_BusinessAccount) => {
  if (CacheBank_BusinessAccount) {
    localStorage.setItem(Session_BusinessAccountId, CacheBank_BusinessAccount);
    axios.defaults.headers.BusinessAccountId = CacheBank_BusinessAccount;
  } else {
    localStorage.removeItem(Session_BusinessAccountId);
    localStorage.removeItem("@CacheBank_");
    delete axios.defaults.headers.BusinessAccountId;
  }
};


export { verify, sign, setSession,setBusinessAccount };
