import React, { Component, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormControlLabel } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment,IconButton,InputLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";



function ValidateToken() {
  const { signUp,isAuthenticated,isInitialized,postApi,user } = useAuth();
const navigate = useNavigate();


let [accessCode, setAccessCode] = useState('');
let [saving, setSalving] = useState(false);
let [errormsg, setErrorMsg] = useState('');


useEffect(() => {
  if(user){
    sendCodeValidation();
  }

}, [user]);


const sendCodeValidation = async (event) => {
  
  let response = await postApi("/user/access/check",{
  });

  if(response.authenticated==true){
    window.location.href="/app";
  }
};


const checkValues=()=>{
  if(!accessCode){
    alert("Você precisa informar o código de acesso");
  }else{
    return true;
  }
}

const handleSubmit = async (event) => {
  event.preventDefault();
  
  setSalving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSalving(false);
    setErrorMsg('')
    return false;
  }

  let response = await postApi("/user/access/validate",{
    code:accessCode
  });
  
  if(response.success){
    window.location.href="/app";
  }
  response_run(response);
};



const response_run= (response)=>{
  if(response.success){
    setSalving(true);

  }else{

    if(response.access_code){
      setErrorMsg(response.email.toString())
    }else{
      setErrorMsg( response.error)
    }
    
    setSalving(false);
  }
}



  

  return (
    <React.Fragment>
       <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src="https://pubstore1.cachebank.com.br/logos/v2/Logomarca-%20Azul.png" width="180" />
          <br></br>
          <Typography  variant="h6">
            Valide o seu acesso
          </Typography>
          <Typography  variant="h7">
            Verifique seu e-mail ou telefone
          </Typography>
          
       
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} mt={5}>
              <Typography  variant="h7">
                Para a sua segurança, foi enviado um E-mail/SMS com o código de acesso a conta.
                <br></br>
                Digite-o abaixo para poder prosseguir
              </Typography>
            </Grid>
              <Grid item xs={12} sm={12}>
                <TextField onChange={e=> setAccessCode(e.target.value)}
                  autoComplete="name"
                  name="Código de acesso"
                  required={true}
                  fullWidth
                  id="codigoacesso"
                  value={accessCode}
                  label="Código de acesso"
                  autoFocus
                />
              </Grid>
             

              
             
              </Grid>
            
            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid>

            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={saving}
              sx={{ mt: 3, mb: 2 }}
            >
              Validar Acesso
            </Button>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default (ValidateToken);
