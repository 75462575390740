import React, { Component, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormControlLabel } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment,IconButton,InputLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";



function SignUp() {
  const { signUp,isAuthenticated,isInitialized } = useAuth();
const navigate = useNavigate();


let [fullname, SetFullName] = useState('');
let [email, setEmail] = useState('');
let [password, setPassword] = useState('');
let [confirmpass, setConfirmPass] = useState('');
let [phone, setPhone] = useState('');
let [showPassword, setShowPassword] = useState(false);
let [tos, setTos] = useState(false);
let [saving, setSalving] = useState(false);
let [errormsg, setErrorMsg] = useState('');


const handleMouseDownPassword = (event) => {
  event.preventDefault();
  setShowPassword(false)
};

const checkValues=()=>{
  if(!fullname){
    alert("Você precisa preencher o nome completo");
  }else if(!email){
    alert("Você precisa preencher o seu e-mail");
  }else if(!password){
    alert("Você precisa preencher a sua senha");
  }else if(!confirmpass){
    alert("Você precisa preencher a sua senha novamente");
  }else if(!phone){
    alert("Você precisa preencher o seu telefone");
  }else if(confirmpass!=password){
    alert("As senhas não conferem.");
  }else if(!tos){
    alert("Você precisa aceitar os termos de uso e privacidade para prosseguir");
  }else{
    return true;
  }
}

const handleSubmit = async (event) => {
  event.preventDefault();
  
  setSalving(true);
  setErrorMsg('')
  if(!checkValues()){
    setSalving(false);
    setErrorMsg('')
    return false;
  }

   let response = await signUp(fullname, email, password, phone.replace(/\D/g, ""));
  if(response.success){
    window.location.href="/sign-up/step2";
  }
  response_run(response);

};



const response_run= (response)=>{
  if(response.success){
    setSalving(true);

  }else{

    if(response.email){
      setErrorMsg(response.email.toString())
    }else if(response.fullname){
      setErrorMsg(response.fullname.toString())
    }else if(response.password){
      setErrorMsg(response.password.toString())
    }else if(response.phone){
      setErrorMsg(response.phone.toString())
    }else{
      setErrorMsg( response.error)
    }
    
    setSalving(false);
  }
}

if (isInitialized && isAuthenticated) {
  return <Navigate to="/app" />;
}

  

  return (
    <React.Fragment>
       <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src="https://pubstore1.cachebank.com.br/logos/v2/Logomarca-%20Azul.png" width="180" />
          <br></br>
          <Typography  variant="h6">
            Abra sua conta em até 3 minutos
          </Typography>
          <Typography variant="caption" gutterBottom>
          Crie uma senha para acessar sua conta junto ao email cadastrado.
      </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField onChange={e=> SetFullName(e.target.value)}
                  autoComplete="name"
                  name="fullname"
                  required={true}
                  fullWidth
                  id="fullname"
                  value={fullname}
                  label="Nome Completo"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField onChange={e=>setEmail(e.target.value)}
                  required
                  fullWidth
                  id="email"
                  label="Seu E-mail"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField onChange={e=> setPassword(e.target.value)}
                  required
                  fullWidth
                  value={password}
                  name="password"
                  label="Sua Senha"
                  type={showPassword?"text":"password"}
                  id="password"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e=> setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField onChange={e=> setConfirmPass(e.target.value)}
                  required
                  fullWidth
                  name="password"
                  
                  value={confirmpass}
                  label="Confirme sua senha"
                  type={showPassword?"text":"password"}
                  id="password"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e=> setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              
            <InputMask
              mask={String(String(phone).replace(/\D/g, "")).substring(2,3)!=3?"(99) 9 9999-9999":"(99) 9999-9999"}
              value={phone}
              disabled={false}
              maskChar="_"
              label="Seu telefone"
              onChange={e=> setPhone(e.target.value)} 
            >
               {() => 
                <TextField   required
                  fullWidth
                  name="phone"
                  label="Seu telefone"
                  type="text"
                  id="phone"
                  autoComplete="phone"
                 
                />}
            </InputMask>

              </Grid>

              <Grid item xs={12}>
              <FormControlLabel 
        control={<Checkbox name="lgpd_agreement"  onClick={e=> setTos(!tos)}/>}
        label={
          <p>
           Eu li e aceito os &nbsp;
            <Link
            target={"_blank"}
              href="https://www.cachebank.com.br/contratos/termo-de-uso/atualizado.pdf"
            >
              Termo de Serviço
            </Link> e o  <Link 
            target={"_blank"}
              href="https://www.cachebank.com.br/contratos/politica-privacidade/atualizado.pdf"
            >
              Termo de Privacidade
            </Link> da Cachê Bank
          </p>
        }
      />
             
              </Grid>
            </Grid>
            
            <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errormsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid>

            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={saving}
              sx={{ mt: 3, mb: 2 }}
            >
              Criar Conta
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Já possui uma conta? Entre agora.
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default (SignUp);
