
import { AppBar, Backdrop, BottomNavigation, BottomNavigationAction, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, Toolbar, Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/material/styles/makeStyles';
import { styled } from '@mui/material/styles';
import PixIcon from '@mui/icons-material/Pix';
import AddIcon from '@mui/icons-material/Add';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Fab from '@mui/material/Fab';
import MoreIcon from '@mui/icons-material/MoreVert';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import { Link, Navigate, useNavigate } from "react-router-dom";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MenuIcon from '@mui/icons-material/Menu';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';


import CreateBoleto from "../pages/Cobrancas/createboleto/index"
import CreateCarne from "../pages/Cobrancas/createcarne/index"
import CreatePix from "../pages/Cobrancas/createpix/index"
import useAuth from '../hooks/useAuth';

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  });



function FooterBar () {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleToogle = (_, reason) => {
    setOpen(!open);
  }
let { ShowModalFunc,showModal } = useAuth();

  const handleCloseBox = (value) => {
  ShowModalFunc(null)
};
const [selectedValueBox, setSelectedValueBox] = React.useState([]);

const navigate = useNavigate();
 
  
  return (
    <React.Fragment>
    <AppBar position="fixed" sx={{ backgroundColor:"#04225d", top: 'auto', bottom: 0 , height:80 }}>
    <Backdrop open={open} />
       <BottomNavigation style={{backgroundColor:"#04225d", color:"white"}}   
      >
        
         <BottomNavigationAction  component={Link} to="/app" icon={<HomeIcon fontSize="large"/>}  style={{color:"white"}} />
         <BottomNavigationAction  component={Link} to="/meus-clientes" icon={<GroupIcon fontSize="large"/>}  style={{color:"white"}} />
         <BottomNavigationAction 
            onBlur={e=> setOpen(false)} icon={
          <StyledFab color="secondary" aria-label="add" onClick={handleToogle}>
          <AddIcon sx={{ fontSize:34}}/>
          <SpeedDial
            ariaLabel="SpeedDial controlled open example"
            sx={{ position: 'absolute', bottom: '100%', right: 16 }}
            hidden={true}
            onClose={e=> setOpen(false) }
            onOpen={handleToogle}
            direction={"up"}
            open={open}
            
          >
           
             
           <SpeedDialAction 
                key={4}
                icon={<DescriptionIcon  />}
                tooltipTitle={"Emitir Carnê"}
                onClick={e=> {ShowModalFunc("emitr_carne");  setOpen(false) } }
                tooltipOpen
                tooltipPlacement='right'
                sx={{
                  whiteSpace:"nowrap"
                }}
              />

              <SpeedDialAction 
                key={3}
                icon={<DescriptionIcon  />}
                tooltipTitle={"Emitir QrCode Pix"}
                onClick={e=> {ShowModalFunc("emitr_pix");  setOpen(false) }}
                tooltipOpen
                tooltipPlacement='right'
                sx={{
                  whiteSpace:"nowrap"
                }}
              />

              <SpeedDialAction 
                key={2}
                icon={<DescriptionIcon   />}
                tooltipTitle={"Emitir Boleto"}
                onClick={e=> {ShowModalFunc("emitr_boleto");  setOpen(false)}  }
                tooltipOpen
                tooltipPlacement='right'
                sx={{
                  whiteSpace:"nowrap"
                }}
                />

             
              <SpeedDialAction 
                key={1}
                icon={<PersonAddIcon />}
                tooltipTitle={"Novo cliente"}
                onClick={e => { navigate("#addNewClient"); setOpen(false); }  }
                tooltipOpen
                component={Link}
                tooltipPlacement='right'
                sx={{
                  whiteSpace:"nowrap"
                }}   />
              </SpeedDial>
          </StyledFab>
         }  style={{color:"white"}} />
         <BottomNavigationAction  component={Link} to="/cobrancas" icon={<ReceiptLongIcon fontSize="large"/>}  style={{color:"white"}} />
         <BottomNavigationAction  component={Link} to="#openBox" icon={<MoreIcon fontSize="large"/>}  style={{color:"white"}} />


       
      </BottomNavigation>
      
    </AppBar>


    
    <CreateBoleto
        selectedValue={selectedValueBox}
        open={showModal=="emitr_boleto"}
        onClose={handleCloseBox}
        key={"boleto"+showModal}
      />
      <CreateCarne
        selectedValue={selectedValueBox}
        open={showModal=="emitr_carne"}
        onClose={handleCloseBox}
        key={"carne"+showModal}
      />

      <CreatePix
        selectedValue={selectedValueBox}
        open={showModal=="emitr_pix"}
        onClose={handleCloseBox}
        key={"pix"+showModal}
      />

    </React.Fragment>
  )}
;
 
export default FooterBar;